import propTypes from 'prop-types';

import { Typography } from '@mui/material'

import { useTheme } from '@mui/material/styles';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

import { useTranslation } from 'react-i18next';

import { getPopulationAsString } from 'utils/statsFormatters';

const CustomTooltip = ({ active, payload, label }) => {

    const { t } = useTranslation();

    if (active && payload && payload.length) {
        const dataPoint = payload[0];
        return (
            <div>
                <Typography variant="body2">
                    {`${t('regionStats.chartPopTrendTooltipYear')}: ${label}`}
                </Typography>
                <Typography variant="body2">
                    {`${t('regionStats.chartPopTrendTooltipPopulation')}: ${getPopulationAsString(dataPoint.value)}`}
                </Typography>
            </div>
        );
    }
  
    return null;
};

CustomTooltip.propTypes = {
    active: propTypes.bool,
    payload: propTypes.arrayOf(propTypes.object),
    label: propTypes.string,
};

const RegionChartPopTrend = ({ popTrend }) => {

    const theme = useTheme();

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={popTrend}
                margin={{
                    top: 5,
                    right: 30,
                    left: 25,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="1" />
                <XAxis dataKey="year" fontSize={14} />
                <YAxis domain={[0, 'auto']} tickFormatter={getPopulationAsString} fontSize={14} />
                <Tooltip content={<CustomTooltip />} />
                <Line
                    stroke={theme.palette.charts.defaultLine}
                    type="monotone"
                    dataKey="count"
                    activeDot={{ r: 8 }}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

RegionChartPopTrend.propTypes = {
    popTrend: propTypes.arrayOf(propTypes.object),
};

export default RegionChartPopTrend;
