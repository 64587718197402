const getValuesForChartsPopTrendFooter = (populationTrend) => {

    const minYear = Math.min(...populationTrend.map(({ year }) => year));
    const maxYear = Math.max(...populationTrend.map(({ year }) => year));

    const populationDiff = populationTrend[populationTrend.length - 1].count - populationTrend[0].count;
    const populationChange = Math.round((populationDiff / populationTrend[0].count) * 100);

    const populationChangeType =
        populationChange > 0 ?
        '$t(countryPanel.charts.popTrend.populationChangeType.increase)' : '$t(countryPanel.charts.popTrend.populationChangeType.decrease)';

    return {
        populationChangeType,
        populationChange: Math.abs(populationChange),
        minYear,
        maxYear,
    };
};

export { getValuesForChartsPopTrendFooter };