import PropTypes from 'prop-types';

import { Link, useLocation } from 'react-router-dom';

import { Pagination, PaginationItem } from '@mui/material';

import { buildPageSelectionURL } from './categoriesSelectionParams';


const AmenitiesPagination = ({ pagesCount, currentPage }) => {
    
    const location = useLocation();

    return (
        <Pagination
            count={pagesCount}
            page={currentPage}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
                <PaginationItem
                    component={Link}
                    to={buildPageSelectionURL(location, item.page)}
                    {...item}
                />
                )
            }
        />
    );
};

AmenitiesPagination.propTypes = {
    pagesCount: PropTypes.number,
    currentPage: PropTypes.number,
};

export default AmenitiesPagination;