import PropTypes from 'prop-types';

import { Box, Card, CardHeader, CardContent, Typography } from '@mui/material';

const StatsTile = ({ title, contentNumber, contentUnits = null, footer }) => (
    <Card sx={{ flexGrow: 1, m: 2 }}>
        <CardHeader title={<Typography variant="subtitle1">{title}</Typography>} />
        <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box sx={{ display: 'flex', alignItems: 'baseline'}}>
                    <Typography variant="h2">
                        {contentNumber}
                    </Typography>
                    {contentUnits && 
                        <Typography variant="subtitle1">
                            &nbsp;{contentUnits}
                        </Typography>
                    }
                </Box>
                <Typography variant="subtitle2" sx={{ color: 'grey.600', fontWeight: 'inherit' }}>
                    {footer}
                </Typography>
            </Box>
        </CardContent>
    </Card>
);

StatsTile.propTypes = {
    title: PropTypes.string,
    contentNumber: PropTypes.string,
    contentUnits: PropTypes.string,
    footer: PropTypes.string,
};

export default StatsTile;