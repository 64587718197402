import PropTypes from 'prop-types';

import { Link, useLocation } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useTheme } from '@mui/system';

import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import GeoRegion from 'services/GeoRegion';

import { AmenityAddress, AmenityTitleLine } from './amenityDisplayUtils';

import { buildCategorySelectionURL } from './categoriesSelectionParams';

const StyledLink = styled(Link)({
    color: 'inherit',
});

const REGION_TYPES = {
    [GeoRegion.LEVEL.VOIVODESHIP]: '$t(regionAmenities.labelAmenityShowSimilarCategories.regionType.voivodeship)',
    [GeoRegion.LEVEL.COUNTY]: '$t(regionAmenities.labelAmenityShowSimilarCategories.regionType.county)',
    [GeoRegion.LEVEL.COMMUNITY]: '$t(regionAmenities.labelAmenityShowSimilarCategories.regionType.community)',
    [GeoRegion.LEVEL.CITY]: '$t(regionAmenities.labelAmenityShowSimilarCategories.regionType.city)',
};

const AmenityRow = ({ region, amenity, categoryNames, categoriesByCategoryKey, onAmenitySelected }) => {

    const theme = useTheme();
    const location = useLocation();
    const { t } = useTranslation();

    const { category } = amenity;
    const { categoryGroupKey } = categoriesByCategoryKey[category];
    const regionKey = REGION_TYPES[region.isCity ? GeoRegion.LEVEL.CITY : region.level];

    return (
        <Box
            sx={{ '& :hover': { backgroundColor: 'grey.300', cursor: 'pointer' }}}
            onClick = {() => onAmenitySelected(amenity) }
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: `1px solid ${theme.palette.primary.lighter}`,
                p: 1,
            }}>
                <Box>
                    <Typography variant="body2" sx={{ color: 'grey.600' }}>
                        <AmenityTitleLine amenity={amenity} categoryNames={categoryNames} />
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'grey.500' }}>
                        <AmenityAddress amenity={amenity} />
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'grey.500' }}>
                        <StyledLink
                            to={buildCategorySelectionURL(location, categoryGroupKey, category)}
                            onClick={(e) => e.stopPropagation()}
                            rel="nofollow"
                        >
                            {t('regionAmenities.labelAmenityShowSimilarCategories', { regionType: regionKey })}
                        </StyledLink>
                    </Typography>
                </Box>
                <Box>
                    <ChevronRightIcon sx={{ color: 'grey.600' }} />
                </Box>
            </Box>
        </Box>
    );
};

AmenityRow.propTypes = {
    region: PropTypes.object,
    amenity: PropTypes.object,
    categoryNames: PropTypes.object,
    categoriesByCategoryKey: PropTypes.object,
    onAmenitySelected: PropTypes.func,
};

export default AmenityRow;