import PropTypes from 'prop-types';

import { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { styled } from '@mui/material/styles';

import MessagePanel from 'components/MessagePanel';
import capitalizeWords from 'utils/capitalizeWords';

import {
    buildCategorySelectionURL,
    buildCategoryGroupSelectionURL,
    buildSelectAllURL,
} from './categoriesSelectionParams'; 

const collectCountsByGroups = (categoryGroups, categoryCounts, categoriesByCategoryKey) => {

    const groupsByGroupKey = {};
    const countsByGroupKey = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const categoryGroup of categoryGroups) {
        groupsByGroupKey[categoryGroup.key] = categoryGroup;
        countsByGroupKey[categoryGroup.key] = [];
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const categoryCount of categoryCounts) {
        const { categoryGroupKey } = categoriesByCategoryKey[categoryCount.category];
        countsByGroupKey[categoryGroupKey].push(categoryCount);
    }

    return countsByGroupKey;
};

const StyledLink = styled(Link)({
    color: 'inherit',
    fontSize: '0.875rem',
});

const GroupCounts = ({ categoryGroup, categoryCounts, categoriesByCategoryKey }) => {

    const { t } = useTranslation();
    const location = useLocation();

    const [groupCount, setGroupCount] = useState(null);
    const [orderedCategoryCounts, setOrderedCategoryCounts] = useState(null);

    useEffect(() => {
        const groupCount = categoryCounts.reduce((aggr, { count }) => aggr + count, 0);
        setGroupCount(groupCount);
    }, [categoryCounts]);

    useEffect(() => {
        const orderedCategoryCounts = categoryCounts.sort(({ category: categoryA }, { category: categoryB }) => {
            const {name: nameA } = categoriesByCategoryKey[categoryA];
            const {name: nameB } = categoriesByCategoryKey[categoryB];
            return nameA.localeCompare(nameB);
        });
        setOrderedCategoryCounts(orderedCategoryCounts);
    }, [categoryCounts, categoriesByCategoryKey]);

    return (
        <>
            {groupCount !== null && groupCount > 0 && orderedCategoryCounts !== null &&
                <MessagePanel>
                    <StyledLink to={buildCategoryGroupSelectionURL(location, categoryGroup.key)}>
                        {t(categoryGroup.title)} ({groupCount})
                    </StyledLink>
                    :&nbsp;
                    {orderedCategoryCounts.map(({ category, count }, index) =>
                        <span key={index}>
                            <StyledLink
                                to={buildCategorySelectionURL(location, categoryGroup.key, category)}
                                rel="nofollow"
                            >
                                {categoriesByCategoryKey[category].name} ({count})
                            </StyledLink>
                            { index < orderedCategoryCounts.length - 1 &&
                                <span>,&nbsp;</span>
                            }
                        </span>
                    )}
                </MessagePanel>
            }
        </>
    )
};

GroupCounts.propTypes = {
    categoryGroup: PropTypes.object,
    categoryCounts: PropTypes.array,
    categoriesByCategoryKey: PropTypes.object,
};

const TITLE_STATS_REGION_TYPE_REGION_KEY = '$t(regionAmenities.titleStats.regionType.region)';
const TITLE_STATS_REGION_TYPE_CITY_KEY = '$t(regionAmenities.titleStats.regionType.city)';

const AmenitiesCounts = ({ region, categoryGroups, categoriesByCategoryKey, categoryCounts, amenitiesTotal }) => {

    const { t } = useTranslation();
    const location = useLocation();
    
    const regionKey = region.isCity ? TITLE_STATS_REGION_TYPE_CITY_KEY : TITLE_STATS_REGION_TYPE_REGION_KEY;

    const [countsByGroupKey, setCountsByGroupKey] = useState(null);

    useEffect(() => {

        const countsByGroupKey = collectCountsByGroups(
            categoryGroups, categoryCounts, categoriesByCategoryKey,
        );
        setCountsByGroupKey(countsByGroupKey);
    
    }, [categoryGroups, categoriesByCategoryKey, categoryCounts]);
    
    return (
        <>
            <MessagePanel>
                {t('regionAmenities.titleStats', { regionType: regionKey, regionName: capitalizeWords(region.name), amenitiesTotal } )}
            </MessagePanel>
            <Box sx={{ mt: 1 }}>
                {categoriesByCategoryKey && countsByGroupKey && categoryGroups.map((categoryGroup, index) =>
                    <GroupCounts
                        key={index}
                        categoryGroup={categoryGroup}
                        categoryCounts={countsByGroupKey[categoryGroup.key]}
                        categoriesByCategoryKey={categoriesByCategoryKey}
                    />
                )}
            </Box>
            <Box sx={{ mt: 1 }}>
                <MessagePanel>
                    <StyledLink to={buildSelectAllURL(location)}>
                        {t('regionAmenities.labelShowAll')}
                    </StyledLink>
                </MessagePanel>
            </Box>
        </>
    );
};

AmenitiesCounts.propTypes = {
    region: PropTypes.object,
    categoryGroups: PropTypes.array,
    categoriesByCategoryKey: PropTypes.object,
    categoryCounts: PropTypes.array,
    amenitiesTotal: PropTypes.number,
};

export default AmenitiesCounts;