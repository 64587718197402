import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import { useSearchParams } from "react-router-dom";

import { Box, Card, CardHeader, CardContent, Typography } from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useTheme } from '@mui/system';

import { useTranslation } from 'react-i18next';

import MessagePanel from 'components/MessagePanel';

import capitalizeWords from 'utils/capitalizeWords';

import RouteDetails from './RouteDetails';
import { formatTime, formatDistance } from './routeUtils';

const ROUTE_ID_QUERY_PARAM = 'route-id';

const RoutePanel = ({ route, onRouteSelected }) => {

    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box
            sx={{ '& :hover': { backgroundColor: 'grey.300', cursor: 'pointer' }}}
            onClick = {() => onRouteSelected(route) }
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'self-end',
                borderTop: `1px solid ${theme.palette.primary.lighter}`,
                p: 1,
            }}>
                <Box>
                    <Typography variant="body2" sx={{ color: 'grey.600' }}>
                        {t('regionDirections.labelRoute')}: {route.startRegionName} - {route.destinationRegionName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'grey.500' }}>
                        {t('regionDirections.labelDrivingTime')}: {formatTime(route.time)}
                        &nbsp;&nbsp;
                        {t('regionDirections.labelDrivingDistance')}: {formatDistance(route.distance)}
                    </Typography>
                </Box>
                <Box>
                    <ChevronRightIcon sx={{ color: 'grey.600' }} />
                </Box>
            </Box>
        </Box>
    );
};

RoutePanel.propTypes = {
    route: PropTypes.object,
    onRouteSelected: PropTypes.func,
};

const prepareRoutes = (routes, routeTypes, sortingProperty) => 
    routes
        .filter(({ type }) => routeTypes.includes(type))
        .sort((a, b) => a[sortingProperty].localeCompare(b[sortingProperty]));

const RegionDirections = ({ region, routes }) => {

    const { t } = useTranslation();

    const countryTeaserKey = region.isCity ?
        'regionDirections.teaserCountryDirectionsCity' :
        'regionDirections.teaserCountryDirectionsRegion';
    const regionTeaserKey = region.isCity ?
        'regionDirections.teaserRegionDirectionsCity' :
        'regionDirections.teaserRegionDirectionsRegion';

    const [countryRoutes, setCountryRoutes] = useState(null);
    useEffect(() => {
        const countryRoutes = prepareRoutes(routes, ['country'], 'startRegionName');
        setCountryRoutes(countryRoutes);
    }, [routes]);

    const [regionRoutes, setRegionRoutes] = useState(null);
    useEffect(() => {
        const regionRoutes = prepareRoutes(routes, ['region', 'nearby'], 'destinationRegionName');
        setRegionRoutes(regionRoutes);
    }, [routes]);

    const [selectedRoute, setSelectedRoute] = useState(null);
    const [searchParams, setSearchParams]= useSearchParams();
    const routeId = searchParams.get(ROUTE_ID_QUERY_PARAM);

    useEffect(() => {
        if (routeId) {
            const routesById = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const route of routes) {
                routesById[route.id] = route;
            }
            const selectedRoute = routesById[routeId];
            if (selectedRoute) {
                setSelectedRoute(selectedRoute);
            }
        }
    }, [routes, routeId]);

    return (
        <>
            {selectedRoute && 
                <RouteDetails
                    route={selectedRoute}
                    onRouteDetailsClosed={
                        () => {
                            setSearchParams((params) => {
                                params.delete(ROUTE_ID_QUERY_PARAM);
                                return params;
                            });
                            setSelectedRoute(null);
                        }
                    }
                />
            }
            {!selectedRoute && 
                <>
                    <Card sx={{ flexGrow: 1, m: 1, mb: 3  }}>
                        <CardHeader title={<Typography variant="subtitle1">{t('regionDirections.titleCountryDirections')}</Typography>} />
                        <CardContent>
                            <MessagePanel message={t(countryTeaserKey, { name: capitalizeWords(region.name) })} />
                            <Box sx={{ mt: 2 }}>
                                {countryRoutes && countryRoutes.map(route =>
                                    <RoutePanel
                                        key={route.id}
                                        route={route}
                                        onRouteSelected={
                                            (route) => {
                                                setSearchParams((params) => {
                                                    params.set(ROUTE_ID_QUERY_PARAM, route.id)
                                                    return params;
                                                });
                                                setSelectedRoute(route);
                                            }
                                        }
                                    />
                                )}
                            </Box>
                        </CardContent>
                    </Card>    
                    <Card sx={{ flexGrow: 1, m: 1  }}>
                    <CardHeader title={<Typography variant="subtitle1">{t('regionDirections.titleRegionDirections')}</Typography>} />
                        <CardContent>
                            <MessagePanel message={t(regionTeaserKey, { name: capitalizeWords(region.name) })} />
                            <Box sx={{ mt: 2 }}>
                                {regionRoutes && regionRoutes.map(route =>
                                    <RoutePanel
                                        key={route.id}
                                        route={route}
                                        onRouteSelected={setSelectedRoute}
                                    />
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </>
            }
        </>
    );
};

RegionDirections.propTypes = {
    region: PropTypes.object,
    routes: PropTypes.array,
};

export default RegionDirections;
