import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import TopAppBar from './TopAppBar';
import BottomAppBar from './BottomAppBar';

const StyledRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const StyledContent = styled('div')({
    flexGrow: 1,
});

export default function NavigationLayout() {
    return (
        <StyledRoot>
            <TopAppBar />
            <StyledContent>
                <Outlet />
            </StyledContent>
            <BottomAppBar />
        </StyledRoot>
    );
}