import PropTypes from 'prop-types';

import { useSearchParams, useLocation, Link } from "react-router-dom";

import { Box } from '@mui/material';

import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import MessagePanel from 'components/MessagePanel';

import CategoryFilterCheckbox from './CategoryFilterCheckbox';

import {
    getSelectionParamsFromModel,
    updateSelectionParamsInURL,
    buildSelectAllURL,
} from './categoriesSelectionParams';

const StyledLink = styled(Link)({
    color: 'inherit',
    fontSize: '0.875rem',
});

const AmenitiesCategoryFilter = ({ categoryGroups, categories, selectedCategories }) => {

    const location = useLocation();
    const { t } = useTranslation();
    const [, setSearchParams] = useSearchParams();

    const updateSelectedCategories = (selectedCategoriesInGroup, updatedCategoryGroupKey) => {

        const updatedSelectedCategories = selectedCategories.filter(
            ({ categoryGroupKey }) => categoryGroupKey !== updatedCategoryGroupKey
        );
        updatedSelectedCategories.push(...selectedCategoriesInGroup);
                
        const {
            categoryGroupsParam,
            categoriesExcludeParam,
        } = getSelectionParamsFromModel(
            updatedSelectedCategories, categories,
        );
        
        updateSelectionParamsInURL(
            categoryGroupsParam, categoriesExcludeParam, setSearchParams,
        );
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                { categoryGroups.map(({ label, key }, index) =>
                    <CategoryFilterCheckbox
                        key={index}
                        categoryGroupNameLabel={label}
                        categories={
                            categories.filter(
                                ({ categoryGroupKey }) => categoryGroupKey === key,
                            )
                        }
                        selectedCategories={selectedCategories}
                        onCategoriesChange={
                            (categories) => { 
                                updateSelectedCategories(categories, key);
                            }
                        }
                    />
                )}
            </Box>
            <Box sx={{ mt: 1, pl: 2 }}>
                <MessagePanel>
                    <StyledLink to={buildSelectAllURL(location)}>
                        {t('regionAmenities.labelSelectAll')}
                    </StyledLink>
                </MessagePanel>
            </Box>        
        </>
    );
};

AmenitiesCategoryFilter.propTypes = {
    categoryGroups: PropTypes.array,
    categories: PropTypes.array,
    selectedCategories: PropTypes.array,
};

export default AmenitiesCategoryFilter;