import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet-async';

import SubRegionsPanel from 'components/region/SubRegionsPanel';

import {
    basicInfo,
    administrativeUnitsCounts,
    populationTrend,
    topPopulationCities,
    topAreaCities,
    topDensityCities,
    topGrowingCities,
    topDecreasingCities,
} from 'data/countryData';

import CountryTeaser from './CountryTeaser';
import CountryTiles from './CountryTiles';
import CountryCharts from './CountryCharts';

const CountryPanel = ({ regionChildren }) => {

    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{'Twoja Okolica .Online - Polska'}</title>
            </Helmet>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
                        {t('countryPanel.country')}
                    </Typography>
                </Box>
                <Box>
                    <CountryTeaser 
                        basicInfo={basicInfo}
                        populationTrend={populationTrend}
                        administrativeUnitsCounts={administrativeUnitsCounts}
                    />
                    <CountryTiles basicInfo={basicInfo} />
                    <CountryCharts
                        basicInfo={basicInfo}
                        populationTrend={populationTrend}
                        topPopulationCities={topPopulationCities}
                        topAreaCities={topAreaCities}
                        topDensityCities={topDensityCities}
                        topGrowingCities={topGrowingCities}
                        topDecreasingCities={topDecreasingCities}
                    />
                    <SubRegionsPanel
                        regionChildren={regionChildren}
                    />
                </Box>
            </Box>
        </>

    );
};

CountryPanel.propTypes = {
    regionChildren: PropTypes.array.isRequired,
};

export default CountryPanel;