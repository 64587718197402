import PropTypes from 'prop-types';

import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import MessagePanel from 'components/MessagePanel';

import { getValuesForCountryIntro } from './textsValues';

const CountryTeaser = ({ basicInfo, populationTrend, administrativeUnitsCounts }) => {

    const { t } = useTranslation();

    const countryIntroValues = useMemo(
        () => getValuesForCountryIntro(basicInfo, populationTrend),
        [basicInfo, populationTrend],
    );

    return (
        <Box sx={{ px: 2, pb: 2 }}>
            <MessagePanel message={t('countryTeaser.intro', countryIntroValues)} dangerous />
            <MessagePanel message={t('countryTeaser.units', administrativeUnitsCounts)} dangerous />
        </Box>
    );
};

CountryTeaser.propTypes = {
    basicInfo: PropTypes.object.isRequired,
    populationTrend: PropTypes.array.isRequired,
    administrativeUnitsCounts: PropTypes.object.isRequired,
};


export default CountryTeaser;
