export const basicInfo = {
    population: 37766327,
    area: 313424858985,
    year: 2022,
    topPopulationCitiesYear: 2022,
    topDensityCitiesYear: 2022,
    fastestGrowingCitiesMinYear: 2005,
    fastestGrowingCitiesMaxYear: 2022,
    fastestDecreasingCitiesMinYear: 2005,
    fastestDecreasingCitiesMaxYear: 2022,
};

export const administrativeUnitsCounts = {
    voivodeships: 16,
    counties: 380,
    cityCounties: 66,
    communities: 2477,
    cityCommunities: 302,
};

export const populationTrend = [
    { year: 2005, count: 38157055 },
    { year: 2006, count: 38125479 },
    { year: 2007, count: 38115641 },
    { year: 2008, count: 38135876 },
    { year: 2009, count: 38167329 },
    { year: 2010, count: 38529866 },
    { year: 2011, count: 38538447 },
    { year: 2012, count: 38533299 },
    { year: 2013, count: 38495659 },
    { year: 2014, count: 38478602 },
    { year: 2015, count: 38437239 },
    { year: 2016, count: 38432992 },
    { year: 2017, count: 38433558 },
    { year: 2018, count: 38411148 },
    { year: 2019, count: 38382576 },
    { year: 2020, count: 38088564 },
    { year: 2021, count: 37907704 },
    { year: 2022, count: 37766327 },
];

export const topPopulationCities = [
    { regionId: '336075', name: 'Warszawa', population: 1861975 },
    { regionId: '449696', name: 'Kraków', population: 803282 },
    { regionId: '451516', name: 'Wrocław', population: 674079 },
    { regionId: '2413295', name: 'Łódź', population: 658444 },
    { regionId: '2456294', name: 'Poznań', population: 541316 },
    { regionId: '1553098', name: 'Gdańsk', population: 486345 },
    { regionId: '404940', name: 'Szczecin', population: 391566 },
    { regionId: '2206549', name: 'Lublin', population: 331243 },
    { regionId: '358688', name: 'Bydgoszcz', population: 330038 },
    { regionId: '1429268', name: 'Białystok', population: 292600 },
];

export const topAreaCities = [
    { regionId: '1553098', name: 'Gdańsk', area: 680741629 },
    { regionId: '336075', name: 'Warszawa', area: 516332069 },
    { regionId: '2603248', name: 'Gdynia', area: 390660702 },
    { regionId: '449696', name: 'Kraków', area: 326468999 },
    { regionId: '404940', name: 'Szczecin', area: 299962747 },
    { regionId: '2413295', name: 'Łódź', area: 292808133 },
    { regionId: '451516', name: 'Wrocław', area: 292383651 },
    { regionId: '2132418', name: 'Zielona Góra', area: 277805890 },
    { regionId: '2456294', name: 'Poznań', area: 261471983 },
    { regionId: '2670477', name: 'Świnoujście', area: 200235764 },
];

export const topDensityCities = [
    { regionId: '1753824', name: 'Legionowo', density: 0.0039022676793600258 },
    { regionId: '336075', name: 'Warszawa', density: 0.003606157958784466 },
    { regionId: '2907539', name: 'Pruszków', density: 0.0034098165612547306 },
    { regionId: '332346', name: 'Chorzów', density: 0.0030539706622957634 },
    { regionId: '1429268', name: 'Białystok', density: 0.0028705177431989502 },
    { regionId: '2984674', name: 'Ełk', density: 0.0028593869002284844 },
    { regionId: '2874764', name: 'Tczew', density: 0.002575537438250756 },
    { regionId: '332334', name: 'Siemianowice Śląskie', density: 0.0025110204960692105 },
    { regionId: '449696', name: 'Kraków', density: 0.0024605154010350614 },
    { regionId: '3017761', name: 'Świdnica', density: 0.0024581923702214495 },
];

const roundToOneFixed = (change) => Math.round(change * 10) / 10;

export const topGrowingCities = [
    { regionId: '2632573', name: 'Rzeszów', growth: roundToOneFixed(24.373813383457698) },
    { regionId: '2132418', name: 'Zielona Góra', growth: roundToOneFixed(17.81155632248077) },
    { regionId: '336075', name: 'Warszawa', growth: roundToOneFixed(9.683045907271225) },
    { regionId: '1553098', name: 'Gdańsk', growth: roundToOneFixed(6.1765778195973065) },
    { regionId: '449696', name: 'Kraków', growth: roundToOneFixed(6.165901650610794) },
    { regionId: '451516', name: 'Wrocław', growth: roundToOneFixed(5.998597334306188) },
    { regionId: '1429268', name: 'Białystok', growth: roundToOneFixed(0.26625728609466215) },
    { regionId: '3891982', name: 'Suwałki', growth: roundToOneFixed(-1.0726453773748368) },
    { regionId: '2661906', name: 'Opole', growth: roundToOneFixed(-1.0726453773748368) },
    { regionId: '2414718', name: 'Żory', growth: roundToOneFixed(-1.642030899457425) },
];

export const topDecreasingCities = [
    { regionId: '334927', name: 'Bytom', decrease: roundToOneFixed(20.414168125442288) },
    { regionId: '1774264', name: 'Zabrze', decrease: roundToOneFixed(18.728136911951566) },
    { regionId: '2003806', name: 'Sosnowiec', decrease: roundToOneFixed(16.30551156020775) },
    { regionId: '2132412', name: 'Konin', decrease: roundToOneFixed(15.742596303718548) },
    { regionId: '2132438', name: 'Częstochowa', decrease: roundToOneFixed(15.637733403540032) },
    { regionId: '2414866', name: 'Piotrków Trybunalski', decrease: roundToOneFixed(15.571733400276145) },
    { regionId: '2659115', name: 'Chełm', decrease: roundToOneFixed(15.004401408450708) },
    { regionId: '2615984', name: 'Włocławek', decrease: roundToOneFixed(14.871726460950985) },
    { regionId: '2103532', name: 'Gliwice', decrease: roundToOneFixed(14.25312482765191) },
    { regionId: '2413295', name: 'Łódź', decrease: roundToOneFixed(14.22355620170187) },
];
