import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import ShapeMap from 'components/ShapeMap';

const calculateBoundingBox = (coordinates) => {

    const lons = [];
    const lats = [];

    coordinates.forEach(([lon, lat]) => {
        lons.push(lon);
        lats.push(lat);
    });

    return {
        minLon: Math.min(...lons),
        maxLon: Math.max(...lons),
        minLat: Math.min(...lats),
        maxLat: Math.max(...lats),
    };
};

const getShapes = (routeCoordinates, selectedSegment) => {

    const shapes = [{
        shapeCoordinates: routeCoordinates,
        featureType: 'LineString',
        layerPaint: { 'line-color': 'blue', 'line-width': 1 },   
    }];
    if (selectedSegment) {
        shapes.push({
            shapeCoordinates: selectedSegment,
            featureType: 'LineString',
            layerPaint: { 'line-color': 'red', 'line-width': 2 },       
        });
    }

    return shapes;
};

const RouteDetailsMap = ({ routeCoordinates, selectedSegment, accessToken }) => {

    const [routeBoundingBox, setRouteBoundingBox] = useState(null);

    useEffect(() => {
        const routeBoundingBox = calculateBoundingBox(selectedSegment || routeCoordinates);
        setRouteBoundingBox(routeBoundingBox);
    }, [routeCoordinates, selectedSegment]);

    return (
        <ShapeMap
            shapes={getShapes(routeCoordinates, selectedSegment)}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            accessToken={accessToken}
            {...routeBoundingBox}
        />
    );    
};

RouteDetailsMap.propTypes = {
    routeCoordinates: PropTypes.array,
    selectedSegment: PropTypes.object,
    accessToken: PropTypes.string,
};

export default RouteDetailsMap;