import { Helmet } from 'react-helmet-async';


// @mui
import {
    Card,
    Container,
} from '@mui/material';

export default function InfoAboutServicePage() {

    return (
        <>
            <Helmet>
                <title>Twoja Okolica .Online | O Serwisie</title>
            </Helmet>

            <Container>

                <Card sx={{ margin: 2, padding: 2 }}>
                    <h3>O Serwisie</h3>
                    <p>Serwis <b>Twoja Okolica .Online</b> jest eksperymentalnym projektem mającym na celu udostępnienie w Internecie praktycznych informacji na temat regionów i miejscowości w Polsce. Serwis adresowany jest zarówno do osób poszukujących informacji na temat regionów, w których mieszkają na stałe, jak i dla osób podróżujących i przebywających w danej okolicy jedynie chwilowo, np. w ramach urlopu lub podróży służbowej.</p>
                    <p>Aktualna wersja umożliwia wyszukiwanie regionów, przedstawia granice administracyjne i udostępnia podstawowe dane demograficzne. Z biegiem czasu serwis planujemy rozszerzyć o bardziej szczegółowe dane topograficzne, demograficzne i podstawowe dane biznesowe, wszystko w formie wygodnej do wyszukiwania i przeglądania.</p>
                    <p>Pozdrawiamy!<br/>Zespół Twoja Okolica .Online</p>
               </Card>
            </Container>
        </>
    );
}