import capitalizeWords from "utils/capitalizeWords";
import GeoRegion from "services/GeoRegion";

import { getPopulationAsString, getAreaAsString, getDensityAsString } from 'utils/statsFormatters';

const adminCentersSnippetKeys = {
    [GeoRegion.LEVEL.COUNTY]: '$t(regionTeaser.county.adminCenters)',
    [GeoRegion.LEVEL.COMMUNITY]: '$t(regionTeaser.community.adminCenters)',
};

const smallCitiesSnippetKeys = {
    [GeoRegion.LEVEL.VOIVODESHIP]: '$t(regionTeaser.voivodeship.smallCities)',
    [GeoRegion.LEVEL.COUNTY]: '$t(regionTeaser.county.smallCities)',
    [GeoRegion.LEVEL.COMMUNITY]: '$t(regionTeaser.community.smallCities)',
    [GeoRegion.LEVEL.CITY]: '$t(regionTeaser.city.smallCities)',
};

const bigCitiesSnippetKeys = {
    [GeoRegion.LEVEL.VOIVODESHIP]: '$t(regionTeaser.voivodeship.bigCities)',
    [GeoRegion.LEVEL.COUNTY]: '$t(regionTeaser.county.bigCities)',
    [GeoRegion.LEVEL.COMMUNITY]: '$t(regionTeaser.community.bigCities)',
    [GeoRegion.LEVEL.CITY]: '$t(regionTeaser.city.bigCities)',
};

const allCitiesSnippetKeys = {
    [GeoRegion.LEVEL.VOIVODESHIP]: '$t(regionTeaser.voivodeship.allCities)',
    [GeoRegion.LEVEL.COUNTY]: '$t(regionTeaser.county.allCities)',
    [GeoRegion.LEVEL.COMMUNITY]: '$t(regionTeaser.community.allCities)',
    [GeoRegion.LEVEL.CITY]: '$t(regionTeaser.city.allCities)',
};

const getValuesForRegionTeaser = (region, parent, popTrend, ranks, stats, childrenCounts, insights, neighbours, regionCities, nearbyCities) => {

    const minYear = Math.min(...popTrend.map(({ year }) => year));
    const maxYear = Math.max(...popTrend.map(({ year }) => year));

    const populationDiff = popTrend[popTrend.length - 1].count - popTrend[0].count;
    const populationChange = Math.round((populationDiff / popTrend[0].count) * 100);

    const populationChangeType =
        populationChange > 0 ?
        '$t(regionPopulationTrend.changeType.increased)' : '$t(regionPopulationTrend.changeType.decreased)';

    const childrenCountsByLevel = childrenCounts.reduce(
        (acc, levelCount) => ({[levelCount.level]: levelCount.count, ...acc}), {},
    );

    const adminCenters = region.regionCenters.filter(({ centerType }) => centerType === 'admin_center');
    const adminCentersSnippetKey = !region.isCity && adminCenters.length ? adminCentersSnippetKeys[region.level] : '';
    const adminCentersStr = adminCenters.map(({ name }) => name).join(', ');

    const getCitiesSnippetKey = (region, keys) => {
        if (region.isCity) {
            return keys[GeoRegion.LEVEL.CITY];
        }
        
        return keys[region.level];
    };

    const cities = regionCities || nearbyCities;
    let eligibleCities;
    if (region.isCity) {
        eligibleCities = cities.filter(({ cityRegionId }) => cityRegionId !== region.id);
    } else {
        eligibleCities = [...cities];
    }

    const filterSmallCitiesPopulation =
        ({ population }) => 
            population > GeoRegion.SMALL_CITY_POPULATION_MIN
            && population < GeoRegion.BIG_CITY_POPULATION_MIN;
    const filterBigCitiesPopulation =
        ({ population }) =>
            population >= GeoRegion.BIG_CITY_POPULATION_MIN;

    const filterSmallCitiesPopulationAndDistance =
        ({ population, distance }) =>
            filterSmallCitiesPopulation({ population })
            && parseInt(distance, 10) < GeoRegion.SMALL_CITY_MAX_DISTANCE;
    const filterBigCitiesPopulationAndDistance =
        ({ population, distance }) =>
            filterBigCitiesPopulation({ population })
            && parseInt(distance, 10) < GeoRegion.BIG_CITY_MAX_DISTANCE;

    const filterSmallCities = region.level === GeoRegion.LEVEL.VOIVODESHIP ?
    filterSmallCitiesPopulation : filterSmallCitiesPopulationAndDistance;
    const filterBigCities = region.level === GeoRegion.LEVEL.VOIVODESHIP ?
        filterBigCitiesPopulation : filterBigCitiesPopulationAndDistance;

    const smallCities = eligibleCities.filter(filterSmallCities);
    const bigCities = eligibleCities.filter(filterBigCities);
    const allCities = smallCities.length && bigCities.length ? [] : [...smallCities, ...bigCities];

    const smallCitiesSnippetKey = !allCities.length && smallCities.length ? getCitiesSnippetKey(region, smallCitiesSnippetKeys) : '';
    const bigCitiesSnippetKey = !allCities.length && bigCities.length ? getCitiesSnippetKey(region, bigCitiesSnippetKeys) : '';
    const allCitiesSnippetKey = allCities.length ? getCitiesSnippetKey(region, allCitiesSnippetKeys) : '';

    const stringifyCities =
        (cities) =>
            cities
                .map(({ name }) => name)
                .sort((name1, name2) => name1.localeCompare(name2))
                .join(', ');

    return {
        'parentRegionName': capitalizeWords(parent.name),
        'regionName': capitalizeWords(region.name),
        'regionAreaRank': ranks.areaRank,
        'regionArea': getAreaAsString(stats.area),
        'regionLocation': `$t(regionTeaser.location.${insights.location})`,
        'regionNeighbours': neighbours.map((neighbour) => capitalizeWords(neighbour.name)).join(', '),
        'regionPopulation': getPopulationAsString(stats.population),
        'regionPopulationRank': ranks.popRank,
        'regionPopulationMinYear': minYear,
        'regionPopulationMaxYear': maxYear,
        'regionPopulationChangeType': populationChangeType,
        'regionPopulationChangeRate': `${Math.abs(populationChange)}%`,
        'regionPopulationDensityRank': ranks.densityRank,
        'regionPopulationDensity': getDensityAsString(stats.density),
        'regionCountiesCount': childrenCountsByLevel[GeoRegion.LEVEL.COUNTY] || 0,
        'regionCommunitiesCount': childrenCountsByLevel[GeoRegion.LEVEL.COMMUNITY] || 0,
        'regionAdminCentersSnippet': adminCentersSnippetKey,
        'regionAdminCenters': adminCentersStr,
        'regionSmallCitiesSnippet': smallCitiesSnippetKey,
        'regionSmallCities': stringifyCities(smallCities),
        'regionBigCitiesSnippet': bigCitiesSnippetKey,
        'regionBigCities': stringifyCities(bigCities),
        'regionAllCitiesSnippet': allCitiesSnippetKey,
        'regionAllCities': stringifyCities(allCities),
    };
};

export { getValuesForRegionTeaser };