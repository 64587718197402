import propTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

const MessagePanel = ({ message, dangerous = false, mx = 0, my = 0, children }) => (
    <Box sx={{ mx, my }}>
        <Typography variant="subtitle2" sx={{ color: 'grey.600', fontWeight: 'inherit' }}>
            {dangerous ? 
                <>                            
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                </>
                :
                <>{message}</>
            }
            {children}
        </Typography>
    </Box>
);

MessagePanel.propTypes = {
    message: propTypes.string,
    dangerous: propTypes.bool,
    mx: propTypes.number,
    my: propTypes.number,
    children: propTypes.node,
};

export default MessagePanel;
