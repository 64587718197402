import PropTypes from 'prop-types';

import { Fragment } from 'react';

import { useParams, Link } from 'react-router-dom';

import { Card, CardHeader, CardContent, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import buildRegionLink from 'routes/buildRegionLink';

import GeoRegion from 'services/GeoRegion';

import styledRegionLink from 'components/region/styledRegionLink';

const StyledLink = styledRegionLink(Link);

const panelTitleKeys = {
    [GeoRegion.LEVEL.COUNTRY]: 'region.subRegionsTitle',
    [GeoRegion.LEVEL.VOIVODESHIP]: 'region.subRegionsTitleVoivodeship',
    [GeoRegion.LEVEL.COUNTY]: 'region.subRegionsTitleCounty',
    [GeoRegion.LEVEL.COMMUNITY]: 'region.subRegionsTitleCommunity',
};

const RegionLink = ({ region, voivodeshipRefName, countyRefName, communityRefName, cityRefName }) => (

    <StyledLink
        to={buildRegionLink(voivodeshipRefName, countyRefName, communityRefName, cityRefName)}
    >
        {region.name}
    </StyledLink>
);

RegionLink.propTypes = {
    region: PropTypes.object.isRequired,
    voivodeshipRefName: PropTypes.string,
    countyRefName: PropTypes.string,
    communityRefName: PropTypes.string,
    cityRefName: PropTypes.string,
};

const SubRegionsPanel = ({ regionChildren }) => {

    const { t } = useTranslation();

    const { voivodeshipRefName, countyRefName, communityRefName, cityRefName } = useParams();

    const regionLevel = GeoRegion.getRegionLevel(voivodeshipRefName, countyRefName, communityRefName, cityRefName);
    const panelTitleKey = panelTitleKeys[regionLevel];

    return (
        <>
            {regionChildren && regionChildren.length > 0 && 
                <Card sx={{ m: 2 }}>
                    <CardHeader title={<Typography variant="subtitle1">{t(panelTitleKey)}</Typography>} />
                    <CardContent>
                        {regionChildren.map((child) =>
                            <Fragment key={child.id}>
                                <RegionLink
                                    key={child.id}
                                    region={child}
                                    voivodeshipRefName={child.level === GeoRegion.LEVEL.VOIVODESHIP ? child.refName : voivodeshipRefName}
                                    countyRefName={child.level === GeoRegion.LEVEL.COUNTY ? child.refName : countyRefName}
                                    communityRefName={child.level === GeoRegion.LEVEL.COMMUNITY ? child.refName : communityRefName}
                                    cityRefName={child.level === GeoRegion.LEVEL.CITY ? child.refName : cityRefName}
                                />
                                &nbsp;&nbsp;&nbsp;
                            </Fragment>
                        )}
                    </CardContent>
                </Card>
            }
        </>
    );
};

SubRegionsPanel.propTypes = {
    regionChildren: PropTypes.array.isRequired,
};


export default SubRegionsPanel;
