import capitalizeWords from 'utils/capitalizeWords';
import GeoRegion from 'services/GeoRegion';

const getValuesForRegionMap = (region) => {

    const regionType = 
        region.level === GeoRegion.LEVEL.CITY ?
            '$t(regionMap.regionType.city)' : '$t(regionMap.regionType.region)';

    return { regionType, region: capitalizeWords(region.name) };
};

export { getValuesForRegionMap };
