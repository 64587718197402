import { Helmet } from 'react-helmet-async';


// @mui
import {
    Card,
    Container,
} from '@mui/material';

export default function InfoOurDataPage() {

    return (
        <>
            <Helmet>
                <title>Twoja Okolica .Online | Kontakt</title>
            </Helmet>

            <Container>

                <Card sx={{ margin: 2, padding: 2 }}>
                    <h3>Kontakt</h3>
                    <p>Serwis <b>Twoja Okolica .Online</b> jest stale rozwijany, nowe elementy serwisu planowane są na bieżąco.<br/>Skontaktuj się z nami pod adresem <a href="mailto:kontakt@twojakolica.online">kontakt@twojakolica.online</a> jeżeli masz pytania, sugestie lub uwagi</p>
               </Card>
            </Container>
        </>
    );
}