import capitalizeWords from 'utils/capitalizeWords';
import GeoRegion from 'services/GeoRegion';

const getValuesForRegionStatsTiles = (region, stats) => {

    const regionType = 
        region.level === GeoRegion.LEVEL.CITY ?
            '$t(regionStatsTiles.regionType.city)' : '$t(regionStatsTiles.regionType.region)';
    const sourceType =
        region.level === GeoRegion.LEVEL.CITY ?
            '$t(regionStatsTiles.sourceType.worldpop)' :  '$t(regionStatsTiles.sourceType.bdl)';

    return {
        regionType,
        sourceType,
        region: capitalizeWords(region.name),
        year: stats.year,
    };
};

const getValuesForRegionPopulationTrend = (region, popTrend) => {

    const regionType = 
        region.level === GeoRegion.LEVEL.CITY ?
            '$t(regionStatsTiles.regionType.city)' : '$t(regionStatsTiles.regionType.region)';

    const minYear = Math.min(...popTrend.map(({ year }) => year));
    const maxYear = Math.max(...popTrend.map(({ year }) => year));

    const populationDiff = popTrend[popTrend.length - 1].count - popTrend[0].count;
    const populationChange = Math.round((populationDiff / popTrend[0].count) * 100);

    const populationChangeType =
        populationChange > 0 ?
        '$t(regionPopulationTrend.changeType.increased)' : '$t(regionPopulationTrend.changeType.decreased)';

    return {
        regionType,
        region: capitalizeWords(region.name),
        minYear,
        maxYear,
        populationChange: Math.abs(populationChange),
        populationChangeType,
    };
};

const getValuesForRegionPopulationRanking = (region, parent, populationRanks, year) => {

    const regionTypes = {
        [GeoRegion.LEVEL.COUNTY]: '$t(regionPopulationRanking.regionType.county)',
        [GeoRegion.LEVEL.COMMUNITY]: '$t(regionPopulationRanking.regionType.community)',
        [GeoRegion.LEVEL.CITY]: '$t(regionPopulationRanking.regionType.city)',
    };
    const regionType = regionTypes[region.level];

    const regionType2 = 
        region.level === GeoRegion.LEVEL.CITY ?
            '$t(regionPopulationRanking.regionType2.city)' : '$t(regionPopulationRanking.regionType2.region)';

    const regionItem = populationRanks.filter(({ regionId }) => regionId === region.id)[0];
    const rank = populationRanks.indexOf(regionItem) + 1;

    return {
        regionType,
        regionType2,
        region: capitalizeWords(region.name),
        parentRegion: capitalizeWords(parent.name),
        rank,
        year,
    };
};

const getValuesForRegionAreaRanking = (region, parent, areaRanks) => {

    const regionTypes = {
        [GeoRegion.LEVEL.COUNTY]: '$t(regionAreaRanking.regionType.county)',
        [GeoRegion.LEVEL.COMMUNITY]: '$t(regionAreaRanking.regionType.community)',
        [GeoRegion.LEVEL.CITY]: '$t(regionAreaRanking.regionType.city)',
    };
    const regionType = regionTypes[region.level];

    const regionType2 = 
        region.level === GeoRegion.LEVEL.CITY ?
            '$t(regionAreaRanking.regionType2.city)' : '$t(regionAreaRanking.regionType2.region)';

    const regionItem = areaRanks.filter(({ regionId }) => regionId === region.id)[0];
    const rank = areaRanks.indexOf(regionItem) + 1;

    return {
        regionType,
        regionType2,
        region: capitalizeWords(region.name),
        parentRegion: capitalizeWords(parent.name),
        rank,
    };
};

const getValuesForRegionDensityRanking = (region, parent, densityRanks, year) => {

    const regionTypes = {
        [GeoRegion.LEVEL.COUNTY]: '$t(regionDensityRanking.regionType.county)',
        [GeoRegion.LEVEL.COMMUNITY]: '$t(regionDensityRanking.regionType.community)',
        [GeoRegion.LEVEL.CITY]: '$t(regionDensityRanking.regionType.city)',
    };
    const regionType = regionTypes[region.level];

    const regionType2 = 
        region.level === GeoRegion.LEVEL.CITY ?
            '$t(regionDensityRanking.regionType2.city)' : '$t(regionDensityRanking.regionType2.region)';

    const regionItem = densityRanks.filter(({ regionId }) => regionId === region.id)[0];
    const rank = densityRanks.indexOf(regionItem) + 1;

    return {
        regionType,
        regionType2,
        region: capitalizeWords(region.name),
        parentRegion: capitalizeWords(parent.name),
        rank,
        year,
    };
};

export {
    getValuesForRegionStatsTiles,
    getValuesForRegionPopulationTrend,
    getValuesForRegionPopulationRanking,
    getValuesForRegionAreaRanking,
    getValuesForRegionDensityRanking,
};
