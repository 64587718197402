const PAGE_QUERY_PARAM = 'page';
const CATEGORY_GROUPS_QUERY_PARAM = 'category-groups';
const CATEGORIES_EXCLUDE_QUERY_PARAM = 'categories-exclude';
const CATEGORIES_INCLUDE_QUERY_PARAM = 'categories-include';

const getSelectionParamsFromURL = (searchParams) => {

    const currentPageParamStr = searchParams.get(PAGE_QUERY_PARAM);
    const currentPageParam = currentPageParamStr ? parseInt(currentPageParamStr, 10) : 1;

    const categoryGroupsParamCSV = searchParams.get(CATEGORY_GROUPS_QUERY_PARAM);
    const categoryGroupsParam = categoryGroupsParamCSV !== null && categoryGroupsParamCSV.split(',');

    const categoriesExcludeParamCSV = searchParams.get(CATEGORIES_EXCLUDE_QUERY_PARAM);
    const categoriesExcludeParam = categoriesExcludeParamCSV !== null && categoriesExcludeParamCSV.split(',');

    const categoriesIncludeParamCSV = searchParams.get(CATEGORIES_INCLUDE_QUERY_PARAM);
    const categoriesIncludeParam = categoriesIncludeParamCSV !== null && categoriesIncludeParamCSV.split(',');

    return {
        currentPageParam, categoryGroupsParam, categoriesExcludeParam, categoriesIncludeParam,
    };
};

const getSelectionParamsFromModel = (selectedCategories, allCategories) => {
    
    const selectedCategoryGroups = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const { categoryGroupKey } of selectedCategories) {
        if (!selectedCategoryGroups.includes(categoryGroupKey)) {
            selectedCategoryGroups.push(categoryGroupKey);
        }
    }

    const selectedCategoryKeys = selectedCategories.map(({ key }) => key);
    const excludedCategories = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const { key, categoryGroupKey } of allCategories) {
        if (selectedCategoryGroups.includes(categoryGroupKey) && !selectedCategoryKeys.includes(key)) {
            excludedCategories.push(key);
        }
    }

    return {
        categoryGroupsParam: selectedCategoryGroups,
        categoriesExcludeParam: excludedCategories,
    };
};

const updateSelectionParamsInURL = (selectedCategoryGroups, excludedCategories, setSearchParams) => {

    setSearchParams((searchParams) => {

        searchParams.set(CATEGORY_GROUPS_QUERY_PARAM, selectedCategoryGroups.join(','));
        
        if (excludedCategories.length) {
            searchParams.set(CATEGORIES_EXCLUDE_QUERY_PARAM, excludedCategories.join(','));
        } else {
            searchParams.delete(CATEGORIES_EXCLUDE_QUERY_PARAM);
        }
        searchParams.delete(PAGE_QUERY_PARAM);
        searchParams.delete(CATEGORIES_INCLUDE_QUERY_PARAM);
        
        return searchParams;
    });
};

const resetPagingParamInURL = (setSearchParams) => {

    setSearchParams((searchParams) => {
        searchParams.delete(PAGE_QUERY_PARAM);
        return searchParams;
    });
};

const buildCategorySelectionURL = (location, categoryGroupKey, categoryKey) => {

    const searchParams = new URLSearchParams(location.search);
    searchParams.set(CATEGORY_GROUPS_QUERY_PARAM, categoryGroupKey);
    searchParams.set(CATEGORIES_INCLUDE_QUERY_PARAM, categoryKey);
    
    searchParams.delete(PAGE_QUERY_PARAM);
    searchParams.delete(CATEGORIES_EXCLUDE_QUERY_PARAM);

    return `${location.pathname}?${searchParams.toString()}${location.hash}`;
};

const buildCategoryGroupSelectionURL = (location, categoryGroupKey) => {

    const searchParams = new URLSearchParams(location.search);
    searchParams.set(CATEGORY_GROUPS_QUERY_PARAM, categoryGroupKey);
    
    searchParams.delete(PAGE_QUERY_PARAM);
    searchParams.delete(CATEGORIES_INCLUDE_QUERY_PARAM);
    searchParams.delete(CATEGORIES_EXCLUDE_QUERY_PARAM);

    return `${location.pathname}?${searchParams.toString()}${location.hash}`;
};

const buildPageSelectionURL = (location, page) => {

    const searchParams = new URLSearchParams(location.search);
    if (page > 1) {
        searchParams.set(PAGE_QUERY_PARAM, page);
    } else {
        searchParams.delete(PAGE_QUERY_PARAM);
    }
    
    return `${location.pathname}?${searchParams.toString()}${location.hash}`;
};

const buildSelectAllURL = (location) => {

    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(CATEGORY_GROUPS_QUERY_PARAM);
    searchParams.delete(PAGE_QUERY_PARAM);
    searchParams.delete(CATEGORIES_INCLUDE_QUERY_PARAM);
    searchParams.delete(CATEGORIES_EXCLUDE_QUERY_PARAM);
    
    return `${location.pathname}?${searchParams.toString()}${location.hash}`;
};

export {
    getSelectionParamsFromURL,
    getSelectionParamsFromModel,
    updateSelectionParamsInURL,
    resetPagingParamInURL,
    buildCategorySelectionURL,
    buildCategoryGroupSelectionURL,
    buildPageSelectionURL,
    buildSelectAllURL,
};
