import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import { useLocation, useSearchParams } from "react-router-dom";

import { Box, Tabs, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';

import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet-async';
import capitalizeWords from 'utils/capitalizeWords';

import RegionBasicInfoPanel from './RegionBasicInfoPanel';
import RegionDirectionsPanel from './RegionDirectionsPanel';
import { RegionServicesPanel, RegionLeisurePanel } from './RegionAmenitiesPanel';
import RegionMapPanel from './RegionMapPanel';

const SECTION_QUERY_PARAM = 'section';

const REGION_SECTIONS = {
    BASIC_INFO: 'basic-info',
    REGION_DIRECTIONS: 'region-directions',
    REGION_SERVICES: 'region-services',
    REGION_LEISURE: 'region-leisure',
    REGION_MAP: 'region-map',
};

const REGION_SECTION_TABS = [
    { name: REGION_SECTIONS.BASIC_INFO, idx: 0 },
    { name: REGION_SECTIONS.REGION_DIRECTIONS, idx: 1 },
    { name: REGION_SECTIONS.REGION_SERVICES, idx: 2 },
    { name: REGION_SECTIONS.REGION_LEISURE, idx: 3 },
    { name: REGION_SECTIONS.REGION_MAP, idx: 4 },
];

const REGION_SECTIONS_TITLE_KEYS = {
    [REGION_SECTIONS.BASIC_INFO]: 'regionTab.pageTitleBasicInformation',
    [REGION_SECTIONS.REGION_DIRECTIONS]: 'regionTab.pageTitleRegionDirections',
    [REGION_SECTIONS.REGION_SERVICES]: 'regionTab.pageTitleRegionServices',
    [REGION_SECTIONS.REGION_LEISURE]: 'regionTab.pageTitleRegionLeisure',
    [REGION_SECTIONS.REGION_MAP]: 'regionTab.pageTitleRegionMap',
};

const getTabIdx = (sectionParam) => {
    const tab = REGION_SECTION_TABS.filter(({ name }) => name === sectionParam)[0];
    if (tab) {
        return tab.idx;
    }
        
    throw new Error(`Unknown section: ${sectionParam}`);
};

const getSection = (tabIdx) => {
    const tab = REGION_SECTION_TABS.filter(({ idx }) => idx === tabIdx)[0];
    if (tab) {
        return tab.name;
    }
    
    throw new Error(`Unknown tab: ${tabIdx}`);
};

const StyledTab = styled(Tab)({
    fontWeight: 'inherit',
    textTransform: 'none',
});

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ height: '100%' }}
            {...other}
        >
            <Box sx={{ height: '100%' }}>
                {children}
            </Box>
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const RegionPanel = ({ region, regionBasics }) => {

    const theme = useTheme();
    const { t } = useTranslation();

    const [tabIdx, setTabIdx] = useState(null);

    const location = useLocation();
    const [searchParams, setSearchParams]= useSearchParams();
    const section = searchParams.get(SECTION_QUERY_PARAM) || REGION_SECTIONS.BASIC_INFO;

    useEffect(() => {
        setTabIdx(getTabIdx(section));
    }, [section]);

    const handleChange = (event, newValue) => {
        
        setTabIdx(newValue);
        event.preventDefault();
        
        const regionSection = getSection(newValue);
        if (regionSection === REGION_SECTIONS.BASIC_INFO) {
            setSearchParams({});
        } else {
            setSearchParams({ [SECTION_QUERY_PARAM]: regionSection });
        }
    };

    return (
        <>
            <Helmet>
                <title>{`Twoja Okolica .Online - ${capitalizeWords(region.name)} - ${t(REGION_SECTIONS_TITLE_KEYS[section])}`}</title>
            </Helmet>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {region && tabIdx !== null &&
                    <>
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
                                {region.name}
                            </Typography>
                        </Box>
                        <Box>
                            <Tabs value={tabIdx} onChange={handleChange} sx={{ borderBottom: `1px solid ${theme.palette.primary.lighter}` }}>
                                <StyledTab
                                    component="a"
                                    label={<Typography variant="subtitle1">{t('regionTab.tabTitleBasicInformation')}</Typography>}
                                    href={location.pathname}
                                />
                                <StyledTab
                                    component="a"
                                    label={<Typography variant="subtitle1">{t('regionTab.tabTitleRegionDirections')}</Typography>}
                                    href={`?${SECTION_QUERY_PARAM}=${REGION_SECTIONS.REGION_DIRECTIONS}`}
                                />
                                <StyledTab
                                    component="a"
                                    label={<Typography variant="subtitle1">{t('regionTab.tabTitleRegionServices')}</Typography>}
                                    href={`?${SECTION_QUERY_PARAM}=${REGION_SECTIONS.REGION_SERVICES}`}
                                    rel="nofollow"
                                />
                                <StyledTab
                                    component="a"
                                    label={<Typography variant="subtitle1">{t('regionTab.tabTitleRegionLeisure')}</Typography>}
                                    href={`?${SECTION_QUERY_PARAM}=${REGION_SECTIONS.REGION_LEISURE}`}
                                    rel="nofollow"
                                />
                                <StyledTab
                                    component="a"
                                    label={<Typography variant="subtitle1">{t('regionTab.tabTitleRegionMap')}</Typography>}
                                    href={`?${SECTION_QUERY_PARAM}=${REGION_SECTIONS.REGION_MAP}`}
                                />
                            </Tabs>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <TabPanel value={tabIdx} index={0}>
                                <>
                                    { tabIdx === 0 &&
                                        <RegionBasicInfoPanel
                                            region={region}                                    
                                            regionBasics={regionBasics}                                    
                                        />
                                    }
                                </>
                            </TabPanel>
                            <TabPanel value={tabIdx} index={1}>
                                <>
                                    { tabIdx === 1 &&
                                        <Box key={region.id} sx={{ height: '100%', p: 2 }}>
                                            <RegionDirectionsPanel region={region} />
                                        </Box>
                                    }
                                </>
                            </TabPanel>
                            <TabPanel value={tabIdx} index={2}>
                                <>
                                    { tabIdx === 2 &&
                                        <Box key={region.id} sx={{ height: '100%', p: 2 }}>
                                            <RegionServicesPanel region={region} />
                                        </Box>
                                    }
                                </>
                            </TabPanel>
                            <TabPanel value={tabIdx} index={3}>
                                <>
                                    { tabIdx === 3 &&
                                        <Box key={region.id} sx={{ height: '100%', p: 2 }}>
                                            <RegionLeisurePanel region={region} />
                                        </Box>
                                    }
                                </>
                            </TabPanel>
                            <TabPanel value={tabIdx} index={4}>
                                <>
                                    { tabIdx === 4 &&
                                        <Box key={region.id} sx={{ height: '100%', p: 2 }}>
                                            <RegionMapPanel
                                                key={region.id}
                                                region={region}
                                                accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                                            />
                                        </Box>
                                    }
                                </>
                            </TabPanel>
                        </Box>
                    </>
                }
            </Box>        
        </>

    );
};

RegionPanel.propTypes = {
    region: PropTypes.object,
    regionBasics: PropTypes.object,
};

export default RegionPanel;