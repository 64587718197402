import PropTypes from 'prop-types';

import { useEffect, useState, useRef } from 'react';

import { Box, Card, CardHeader, CardContent, IconButton, Typography } from '@mui/material';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import MapIcon from '@mui/icons-material/Map';

import { useTheme } from '@mui/system';

import { useTranslation } from 'react-i18next';

import MessagePanel from 'components/MessagePanel';

import servicesContext from 'servicesContext';

import decodeValhallaShape from 'utils/decodeValhallaShape';

import { formatTime, formatDistance } from './routeUtils';
import RouteDetailsMap from './RouteDetailsMap';

const { regionDirectionsService } = servicesContext;

const CardHeaderTitle = ({ route, onRouteDetailsClosed }) => {
 
    const { t } = useTranslation();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
        }}>
            <Box>
                <IconButton onClick={onRouteDetailsClosed}>
                    <ChevronLeftRoundedIcon />
                </IconButton>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    {t('regionDirections.labelRoute')}: {route.startRegionName} - {route.destinationRegionName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    {t('regionDirections.labelDrivingTime')}: {formatTime(route.time)}
                    &nbsp;&nbsp;
                    {t('regionDirections.labelDrivingDistance')}: {formatDistance(route.distance)}
                </Typography>
            </Box>
        </Box>
    );
}

CardHeaderTitle.propTypes = {
    route: PropTypes.object,
    onRouteDetailsClosed: PropTypes.func,
};

const RouteDetails = ({ route, onRouteDetailsClosed }) => {

    const theme = useTheme();
    const { t } = useTranslation();
 
    const [routeDetails, setRouteDetails] = useState(null);
    const [routeCoordinates, setRouteCoordinates] = useState(null);
    const [segmentIndices, setSegmentIndices] = useState(null);

    useEffect(() => {

        const fetch = async () => {
            
            const routeDetails = await regionDirectionsService.getRouteDetails(route.id);
            setRouteDetails(routeDetails);

            const routeCoordinates =  decodeValhallaShape(routeDetails.shape);
            setRouteCoordinates(routeCoordinates);
        };

        fetch();

    }, [route.id]);

    const mapRef = useRef(null);

    return (
        <>
            {routeDetails &&
                <Card sx={{ flexGrow: 1, m: 1, mb: 3  }}>
                    <CardHeader title={<CardHeaderTitle route={route} onRouteDetailsClosed={onRouteDetailsClosed} />} />
                    <CardContent sx={{ ml: 2 }}>

                        <Box
                            sx={{ borderTop: `1px solid ${theme.palette.primary.lighter}`, p: 1 }}
                        >
                            <MessagePanel>
                                {t('regionDirections.labelStartLocation', { name: route.startRegionName })}
                            </MessagePanel>
                        </Box>
                        {routeDetails.maneuvers.map((maneuver, idx) =>
                            <Box
                                key={idx}
                                sx={{ '& :hover': { backgroundColor: 'grey.300', cursor: 'pointer' }}}
                                onClick = { () => {
                                    setSegmentIndices({ start: maneuver.begin_shape_index, end: maneuver.end_shape_index + 1 });
                                    mapRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'self-end',
                                        borderTop: `1px solid ${theme.palette.primary.lighter}`,
                                        p: 1,
                                    }}
                                >
                                    <Box>
                                        <MessagePanel>{maneuver.verbal_pre_transition_instruction}</MessagePanel>
                                        {maneuver.verbal_post_transition_instruction &&
                                            <MessagePanel>{maneuver.verbal_post_transition_instruction}</MessagePanel>
                                        }
                                        </Box>
                                    <Box>
                                        <MapIcon sx={{ color: 'grey.600' }} />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <Box
                            sx={{
                                borderTop: `1px solid ${theme.palette.primary.lighter}`,
                                borderBottom: `1px solid ${theme.palette.primary.lighter}`,
                                p: 1,
                            }}
                        >
                            <MessagePanel>
                                    {t('regionDirections.labelDestinationLocation', { name: route.destinationRegionName })}
                            </MessagePanel>
                        </Box>

                        {routeCoordinates &&
                            <div ref={mapRef}>
                                <Box sx={{ pb: 1, pt: 1, height: '600px' }}>
                                    <RouteDetailsMap
                                        routeCoordinates={routeCoordinates}
                                        selectedSegment={
                                            segmentIndices ? routeCoordinates.slice(segmentIndices.start, segmentIndices.end) : null
                                        }
                                        accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                                    />
                                </Box>
                            </div>
                        }
                    </CardContent>
                </Card>    
            }
        </>
    );
}

RouteDetails.propTypes = {
    route: PropTypes.object,
    onRouteDetailsClosed: PropTypes.func,
};

export default RouteDetails;