import PropTypes from 'prop-types';

import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Card, CardHeader, CardContent, Typography } from '@mui/material'

import MessagePanel from 'components/MessagePanel'
import RegionChartPopTrend from 'components/region/RegionChartPopTrend';
import RegionChartRanking from 'components/region/RegionChartRanking';
import { getPopulationAsString, getAreaAsString, getDensityAsString } from 'utils/statsFormatters';

import { getValuesForChartsPopTrendFooter } from './textsValues';

const CountryCharts = ({
    basicInfo,
    populationTrend,
    topPopulationCities,
    topAreaCities,
    topDensityCities,
    topGrowingCities,
    topDecreasingCities,
}) => {

    const { t } = useTranslation();

    const valuesForChartsPopTrendFooter = useMemo(
        () => getValuesForChartsPopTrendFooter(populationTrend),
        [populationTrend],
    );

    return (
        <>
            <Card sx={{ flexGrow: 1, m: 2  }}>
                <CardHeader title={<Typography variant="subtitle1">{t('countryPanel.charts.popTrend.title', valuesForChartsPopTrendFooter)}</Typography>} />
                <CardContent>
                    <Box sx={{ height: 400 }}>
                        <RegionChartPopTrend popTrend={populationTrend} />
                    </Box>
                    <MessagePanel message={t('countryPanel.charts.popTrend.footer', valuesForChartsPopTrendFooter)} />
                </CardContent>
            </Card>
            <Card sx={{ flexGrow: 1, m: 2  }}>
                <CardHeader title={<Typography variant="subtitle1">{t('countryPanel.charts.topPopulationCities.title', basicInfo)}</Typography>}/>
                <CardContent>
                    <Box>
                        <RegionChartRanking
                            ranks={topPopulationCities}
                            rankProperty="population"
                            rankLabel={t('regionStats.chartPopTrendTooltipPopulation')}
                            rankValueFormatter={getPopulationAsString}
                        />
                    </Box>
                </CardContent>
            </Card>
            <Card sx={{ flexGrow: 1, m: 2  }}>
                <CardHeader title={<Typography variant="subtitle1">{t('countryPanel.charts.topAreaCities.title')}</Typography>}/>
                <CardContent>
                    <Box>
                        <RegionChartRanking
                            ranks={topAreaCities}
                            rankProperty="area"
                            rankLabel={t('countryPanel.charts.topAreaCities.label')}
                            rankValueFormatter={getAreaAsString}
                        />
                    </Box>
                </CardContent>
            </Card>
            <Card sx={{ flexGrow: 1, m: 2  }}>
                <CardHeader title={<Typography variant="subtitle1">{t('countryPanel.charts.topDensityCities.title', basicInfo)}</Typography>}/>
                <CardContent>
                    <Box>
                        <RegionChartRanking
                            ranks={topDensityCities}
                            rankProperty="density"
                            rankLabel={t('countryPanel.charts.topDensityCities.label')}
                            rankValueFormatter={getDensityAsString}
                        />
                    </Box>
                </CardContent>
            </Card>
            <Card sx={{ flexGrow: 1, m: 2  }}>
                <CardHeader title={<Typography variant="subtitle1">{t('countryPanel.charts.fastestGrowingCities.title', basicInfo)}</Typography>}/>
                <CardContent>
                    <Box>
                        <RegionChartRanking
                            ranks={topGrowingCities}
                            rankProperty="growth"
                            rankLabel={t('countryPanel.charts.fastestGrowingCities.label')}
                            rankValueFormatter={(growth) => `${growth}%`}
                        />
                    </Box>
                </CardContent>
            </Card>
            <Card sx={{ flexGrow: 1, m: 2  }}>
                <CardHeader title={<Typography variant="subtitle1">{t('countryPanel.charts.fastestDecreasingCities.title', basicInfo)}</Typography>}/>
                <CardContent>
                    <Box>
                        <RegionChartRanking
                            ranks={topDecreasingCities}
                            rankProperty="decrease"
                            rankLabel={t('countryPanel.charts.fastestDecreasingCities.label')}
                            rankValueFormatter={(decrease) => `${decrease}%`}
                        />
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

CountryCharts.propTypes = {
    basicInfo: PropTypes.object.isRequired,
    populationTrend: PropTypes.array.isRequired,
    topPopulationCities: PropTypes.array.isRequired,
    topAreaCities: PropTypes.array.isRequired,
    topDensityCities: PropTypes.array.isRequired,
    topGrowingCities: PropTypes.array.isRequired,
    topDecreasingCities: PropTypes.array.isRequired,

};

export default CountryCharts;
