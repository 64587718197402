import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import ShapeMap from 'components/ShapeMap';

const calculateBoundingBox = (boundaries) => {

    const minLons = [];
    const maxLons = [];
    const minLats = [];
    const maxLats = [];

    const boundingPolygons = boundaries.map((polygon) => polygon[0]);

    boundingPolygons.forEach((currBoundingPolygon) => {
        const lons = currBoundingPolygon.map((loc) => loc[0]);
        const lats = currBoundingPolygon.map((loc) => loc[1]);
        
        minLons.push(Math.min(...lons));
        maxLons.push(Math.max(...lons));
        minLats.push(Math.min(...lats));
        maxLats.push(Math.max(...lats));    
    });

    return {
        minLon: Math.min(...minLons),
        maxLon: Math.max(...maxLons),
        minLat: Math.min(...minLats),
        maxLat: Math.max(...maxLats),
    };
};

const RegionMap = ({ boundaries, accessToken }) => {

    const [regionBoundingBox, setRegionBoundingBox] = useState(null);

    useEffect(() => {
        const regionBoundingBox = calculateBoundingBox(boundaries);
        setRegionBoundingBox(regionBoundingBox);
    }, [boundaries]);

    return (
        <ShapeMap
            shapes={[{
                shapeCoordinates: boundaries,
                featureType: 'MultiPolygon',
                layerPaint: { 'line-color': 'red', 'line-width': 1 },
            }]}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            accessToken={accessToken}
            {...regionBoundingBox}
        />
    );    
};

RegionMap.propTypes = {
    boundaries: PropTypes.array,
    accessToken: PropTypes.string,
};

export default RegionMap;