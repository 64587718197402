import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import SearchToolbar from 'components/SearchPanel/SearchToolbar';

const AmenitiesTextFilter = ({ onSearchTextChange }) => {

    const { t } = useTranslation();

    return (
        <>
            <SearchToolbar
                placeholderText={t('regionAmenities.labelAmenitiesSearch')}
                onFilterValueChange={onSearchTextChange}
            />
        </>
    );
};

AmenitiesTextFilter.propTypes = {
    onSearchTextChange: PropTypes.func,
};

export default AmenitiesTextFilter;