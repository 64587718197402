import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import { Box, Card, CardHeader, CardContent, IconButton, Typography } from '@mui/material';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

import servicesContext from 'servicesContext';

import AmenityDetailsMap from './AmenityDetailsMap';
import { AmenityAddress, AmenityTitleLine } from './amenityDisplayUtils';

const { regionAmenitiesService } = servicesContext;

const CardHeaderTitle = ({ amenity, categoryNames, onAmenityDetailsClosed }) => (
    <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
    }}>
        <Box>
            <IconButton onClick={onAmenityDetailsClosed}>
                <ChevronLeftRoundedIcon />
            </IconButton>
        </Box>
        <Box>
            <Box>
                <Typography variant="subtitle1">
                    <AmenityTitleLine amenity={amenity} categoryNames={categoryNames} />
                </Typography>
                <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    <AmenityAddress amenity={amenity} />
                </Typography>
            </Box>
        </Box>
    </Box>
);

CardHeaderTitle.propTypes = {
    amenity: PropTypes.object,
    categoryNames: PropTypes.object,
    onAmenityDetailsClosed: PropTypes.func,
};

const AmenityDetails = ({ amenity, categoryNames, onAmenityDetailsClosed }) => {

    const [amenityDetails, setAmenityDetails] = useState(null);

    useEffect(() => {

        const fetch = async () => {
            
            const amenityDetails = await regionAmenitiesService.getAmenityDetails(amenity.id);
            setAmenityDetails(amenityDetails);
        };

        fetch();

    }, [amenity.id]);

    return (
        <Card sx={{ flexGrow: 1, m: 1, mb: 3  }}>
            <CardHeader title={
                    <CardHeaderTitle
                        amenity={amenity}
                        categoryNames={categoryNames}
                        onAmenityDetailsClosed={onAmenityDetailsClosed}
                    />
                }
            />
            { amenityDetails &&
                <CardContent sx={{ ml: 2 }} >
                    <Box sx={{ pb: 1, pt: 1, height: '600px' }}>
                        <AmenityDetailsMap
                            amenityCoordinates={amenityDetails.coordinates.coordinates}
                            accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                        />
                    </Box>            
                </CardContent>
            }
        </Card>    
    );
}

AmenityDetails.propTypes = {
    amenity: PropTypes.object,
    categoryNames: PropTypes.object,
    onAmenityDetailsClosed: PropTypes.func,
};

export default AmenityDetails;