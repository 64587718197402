import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

import MessagePanel from 'components/MessagePanel';
import StatsTile from 'components/StatsTile';

import { getPopulationAsString, getAreaAsString, getDensityAsString } from 'utils/statsFormatters';

const CountryTiles = ({ basicInfo }) => {

    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                <StatsTile
                    title={t('regionStats.tileTitlePopulation')}
                    contentNumber={getPopulationAsString(basicInfo.population)}
                />
                <StatsTile
                    title={t('regionStats.tileTitleArea')}
                    contentNumber={getAreaAsString(basicInfo.area)}
                    contentUnits={t('regionStats.tileAreaUnits')}
                />
                <StatsTile
                    title={t('regionStats.tileTitleDensity')}
                    contentNumber={getDensityAsString(basicInfo.population / basicInfo.area)}
                    contentUnits={t('regionStats.tileDensityUnits')}
                />
            </Box>
            <MessagePanel
                message={t('countryPanel.tiles.footer', basicInfo)}
                mx={2}
                dangerous
            />
        </>
    );
};

CountryTiles.propTypes = {
    basicInfo: PropTypes.object,
};

export default CountryTiles;