import 'mapbox-gl/dist/mapbox-gl.css';

import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { BrowserRouter, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components-material-kit/chart';
import ScrollToTop from './components-material-kit/scroll-to-top';

import servicesContext from './servicesContext';
import { ServicesContext } from './context';

// ----------------------------------------------------------------------

const ViewTracker = () => {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return (<></>);
};

export default function App() {


    return (
        <ServicesContext.Provider value={servicesContext}>
            <HelmetProvider>
                <BrowserRouter>
                    <ViewTracker />
                    <ThemeProvider>
                        <ScrollToTop />
                        <StyledChart />
                        <Router />
                    </ThemeProvider>
                </BrowserRouter>
            </HelmetProvider>
        </ServicesContext.Provider>
    );
}
