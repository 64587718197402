import PropTypes from 'prop-types';

import { useState, useEffect, useRef } from 'react';

import { Box, Button, Checkbox, FormControlLabel, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useTranslation } from 'react-i18next';

const areAllChecked = (checked) => {

    let allChecked = true;

    // eslint-disable-next-line no-restricted-syntax
    for (const checkedItem of checked) {
        allChecked = allChecked && checkedItem;
    }
    return allChecked;
};

const areAnyCheckedDifferently = (checked) => {

    let trueFound = false;
    let falseFound = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const checkedItem of checked) {
        trueFound = trueFound || checkedItem;
        falseFound = falseFound || !checkedItem;
    }
    return trueFound && falseFound;
};

const setAllChecked = (checked, value) => {
    for (let idx = 0; idx < checked.length; idx += 1) {
        checked[idx] = value;
    }
};

const getSelectedCategories = (checked, categories) => {

    const selectedCategories = [];
    for (let idx = 0; idx < checked.length; idx += 1) {
        if (checked[idx]) {
            selectedCategories.push(categories[idx]);
        }
    }

    return selectedCategories;
};

const Label = ({ children }) => (
    <Typography
        variant="subtitle2"
        fontWeight="regular"
        sx={{ whiteSpace: 'nowrap', textTransform: 'none' }}>
            {children}
    </Typography>
);

Label.propTypes = {
    children: PropTypes.node,
};

const CategoryFilterCheckbox = ({
    categoryGroupNameLabel, categories, selectedCategories, onCategoriesChange,
}) => {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        const selectedCategoriesKeys = selectedCategories.map(({ key }) => key);
        const checked = categories.map(({ key }) => selectedCategoriesKeys.includes(key));
        setChecked(checked);
    }, [selectedCategories, categories]);

    const buttonRef = useRef(null);

    const handleArrowClick = () => {
        setOpen(!open);
    };
  
    const handleClose = () => {
        setOpen(false);
    };

    const handleCategoryGroupChange = (event) => {
        const updatedChecked = [...checked];
        setAllChecked(updatedChecked, event.target.checked);
        setChecked(updatedChecked);
        onCategoriesChange(getSelectedCategories(updatedChecked, categories));
    };
  
    const getHandleCategoryChange = (index) => (
        (event) => {
            const updatedChecked = [...checked];
            updatedChecked[index] = event.target.checked;
            setChecked(updatedChecked);
            onCategoriesChange(getSelectedCategories(updatedChecked, categories));
        }
    );
     
    const categoryCheckboxes = categories.map((category, index) =>
        <FormControlLabel
            key={index}
            label={<Label>{category.name}</Label>}
            control={<Checkbox checked={checked[index]} onChange={getHandleCategoryChange(index)} />}
        />    
    );

    return (
        <div>
            <Box display="flex" alignItems="center">
                <Button ref={buttonRef} sx={{ m: 0, p: 0.4 }}>
                    <FormControlLabel
                        sx={{ m: 0 }}
                        label={<Label>{t(categoryGroupNameLabel)}</Label>}
                        control={
                            <Checkbox
                                checked={areAllChecked(checked)}
                                indeterminate={areAnyCheckedDifferently(checked)}
                                onChange={handleCategoryGroupChange}
                            />
                        }
                    />
                </Button>
                <IconButton onClick={handleArrowClick} sx={{ p: 0.4 }}>
                    <ExpandMoreIcon />
                </IconButton>
            </Box>
            <Menu
                sx={{ '& .MuiList-root': { p: 0 } }}
                anchorEl={buttonRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                open={open}
                onClose={handleClose}
            >
                <Box display="flex" alignItems="center">
                    <Button>
                        <FormControlLabel
                            label={<Label>{t(categoryGroupNameLabel)}</Label>}
                            control={
                                <Checkbox
                                    checked={areAllChecked(checked)}
                                    indeterminate={areAnyCheckedDifferently(checked)}
                                    onChange={handleCategoryGroupChange}
                                />
                            }
                        />
                    </Button>
                    <IconButton onClick={handleArrowClick}>
                        <ExpandLessIcon />
                    </IconButton>
                </Box>
                {categoryCheckboxes.map((item, index) => (
                    <MenuItem key={index}>
                        {item}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

CategoryFilterCheckbox.propTypes = {
    categoryGroupNameLabel: PropTypes.string,
    categories: PropTypes.array,
    selectedCategories: PropTypes.array,
    onCategoriesChange: PropTypes.func,
};

export default CategoryFilterCheckbox;