import PropTypes from 'prop-types';

import { useMemo } from 'react';

import { Box, Card, CardHeader, CardContent, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next';

import capitalizeWords from 'utils/capitalizeWords';
import MessagePanel from 'components/MessagePanel'
import { getPopulationAsString, getAreaAsString, getDensityAsString } from 'utils/statsFormatters';

import RegionChartPopTrend from 'components/region/RegionChartPopTrend';
import RegionChartRanking from 'components/region/RegionChartRanking';

import {
    getValuesForRegionPopulationTrend,
    getValuesForRegionPopulationRanking,
    getValuesForRegionAreaRanking,
    getValuesForRegionDensityRanking,
} from './textsValues';

const RegionCharts = ({ region, siblings, parent, popTrend, parentRegionRanks, stats }) => {

    const { t } = useTranslation();

    const siblingNames = useMemo(
        () => {
            const siblingNames = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const sibling of siblings) {
                siblingNames[sibling.id] = capitalizeWords(sibling.name);
            }
            return siblingNames;
        },
        [siblings],
    );

    const { popRanks, areaRanks, densityRanks } = parentRegionRanks;

    return (
        <>
            {popTrend && 
                <Card sx={{ flexGrow: 1, m: 2  }}>
                    <CardHeader title={<Typography variant="subtitle1">{t('regionStats.chartPopTrendTitle')}</Typography>} />
                    <CardContent>
                        <Box sx={{ height: 400 }}>
                            <RegionChartPopTrend popTrend={popTrend} />
                        </Box>
                        <MessagePanel message={t('regionPopulationTrend', getValuesForRegionPopulationTrend(region, popTrend))} />
                    </CardContent>
                </Card>
            }
            {siblings &&
                <>
                    <Card sx={{ flexGrow: 1, m: 2  }}>
                        <CardHeader title={<Typography variant="subtitle1">{t('regionStats.chartRankingPopTitle')}</Typography>}/>
                        <CardContent>
                            <Box>
                                <RegionChartRanking
                                    regionId={region.id}
                                    ranks={popRanks.map((item) => ({ name: siblingNames[item.regionId], ...item }))}
                                    rankProperty="population"
                                    rankLabel={t('regionStats.chartRankingPopValueLabel')}
                                    rankValueFormatter={getPopulationAsString}
                                />
                            </Box>
                            <MessagePanel message={t('regionPopulationRanking', getValuesForRegionPopulationRanking(region, parent, parentRegionRanks.popRanks, stats.year))} />
                        </CardContent>
                    </Card>
                    <Card sx={{ flexGrow: 1, m: 2  }}>
                        <CardHeader title={<Typography variant="subtitle1">{t('regionStats.chartRankingAreaTitle')}</Typography>}/>
                        <CardContent>
                            <Box>
                                <RegionChartRanking
                                    regionId={region.id}
                                    ranks={areaRanks.map((item) => ({ name: siblingNames[item.regionId], ...item }))}
                                    rankProperty="area"
                                    rankLabel={t('regionStats.chartRankingAreaValueLabel')}
                                    rankValueFormatter={getAreaAsString}
                                />
                            </Box>
                            <MessagePanel message={t('regionAreaRanking', getValuesForRegionAreaRanking(region, parent, parentRegionRanks.areaRanks))} />
                        </CardContent>
                    </Card>
                    <Card sx={{ flexGrow: 1, m: 2  }}>
                        <CardHeader title={<Typography variant="subtitle1">{t('regionStats.chartRankingDensityTitle')}</Typography>} />
                        <CardContent>
                            <Box>
                                <RegionChartRanking
                                    regionId={region.id}
                                    ranks={densityRanks.map((item) => ({ name: siblingNames[item.regionId], ...item }))}
                                    rankProperty="density"
                                    rankLabel={t('regionStats.chartRankingDensityValueLabel')}
                                    rankValueFormatter={getDensityAsString}
                                />
                            </Box>
                            <MessagePanel message={t('regionDensityRanking', getValuesForRegionDensityRanking(region, parent, parentRegionRanks.densityRanks, stats.year))} />
                        </CardContent>
                    </Card>
                </>
            }
        </>
    );
};

RegionCharts.propTypes = {
    region: PropTypes.object.isRequired,
    parent: PropTypes.object.isRequired,
    siblings: PropTypes.array.isRequired,
    popTrend: PropTypes.array.isRequired,
    parentRegionRanks: PropTypes.object.isRequired,
    stats: PropTypes.object.isRequired,
};

export default RegionCharts;
