import PropTypes from 'prop-types';

import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import buildRegionLink from 'routes/buildRegionLink';

import GeoRegion from 'services/GeoRegion';

import styledRegionLink from 'components/region/styledRegionLink';

const StyledMenuItem = styledRegionLink(MenuItem);

const RegionLink = ({ region, voivodeshipRefName, countyRefName, communityRefName, cityRefName, handleClose }) => (

    <StyledMenuItem
        to={buildRegionLink(voivodeshipRefName, countyRefName, communityRefName, cityRefName)}
        component={Link}
        onClick={handleClose}
    >
        {region.name}
    </StyledMenuItem>
);

RegionLink.propTypes = {
    voivodeshipRefName: PropTypes.string,
    countyRefName: PropTypes.string,
    communityRefName: PropTypes.string,
    cityRefName: PropTypes.string,
    region: PropTypes.object,
    handleClose: PropTypes.func,
};

const selectLabelKeys = {
    [GeoRegion.LEVEL.COUNTRY]: 'header.selectVoivodeshipId',
    [GeoRegion.LEVEL.VOIVODESHIP]: 'header.selectCounty',
    [GeoRegion.LEVEL.COUNTY]: 'header.selectCommunity',
    [GeoRegion.LEVEL.COMMUNITY]: 'header.selectCity',
};

const SelectSubRegion = ({ regionChildren }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const { voivodeshipRefName, countyRefName, communityRefName, cityRefName } = useParams();

    const regionLevel = GeoRegion.getRegionLevel(voivodeshipRefName, countyRefName, communityRefName, cityRefName);
    const selectLabelKey = selectLabelKeys[regionLevel];

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { t } = useTranslation();
  
    return (
        <div>
            {regionChildren && regionChildren.length > 0 &&
                <>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ py: 0.5, px: 1, textTransform: 'none' }}
                        variant="outlined"
                        endIcon={<ArrowDropDownIcon />}
                    >
                        {t(selectLabelKey)}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {regionChildren.map((child) =>
                            <RegionLink
                                key={child.id}
                                region={child}
                                voivodeshipRefName={child.level === GeoRegion.LEVEL.VOIVODESHIP ? child.refName : voivodeshipRefName}
                                countyRefName={child.level === GeoRegion.LEVEL.COUNTY ? child.refName : countyRefName}
                                communityRefName={child.level === GeoRegion.LEVEL.COMMUNITY ? child.refName : communityRefName}
                                cityRefName={child.level === GeoRegion.LEVEL.CITY ? child.refName : cityRefName}
                                handleClose={handleClose}
                            />
                        )}
                    </Menu>
                </>
            }
        </div>
    );
};

SelectSubRegion.propTypes = {
    regionChildren: PropTypes.array.isRequired,
};

export default SelectSubRegion;