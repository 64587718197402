import PropTypes from 'prop-types';

import { Typography } from '@mui/material'

import { useTheme } from '@mui/material/styles';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

const CustomTooltip = ({ active, payload, label, valueLabel, rankValueFormatter }) => {

    if (active && payload && payload.length) {
        const dataPoint = payload[0];
        return (
            <div>
                <Typography variant="body2">
                    {label}
                </Typography>
                <Typography variant="body2" >
                    {`${valueLabel}: ${rankValueFormatter(dataPoint.value)}`}
                </Typography>
            </div>
        );
    }
  
    return null;
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    valueLabel: PropTypes.string,
    rankValueFormatter: PropTypes.func,
};

const MAX_LABEL_LENGTH = 18;
const LABEL_FONT_SIZE = 13;
const LABEL_PANEL_WIDTH = 200;

const nameFormatter = (name) => (
    name.length > MAX_LABEL_LENGTH ? `${name.substring(0, MAX_LABEL_LENGTH)}...` : name
);

const RegionChartRanking = ({
    regionId, ranks, rankProperty, rankLabel, rankValueFormatter,
}) => {

    const theme = useTheme();

    const mapRankingItem = (rankingItem) => ({
        fill: rankingItem.regionId !== regionId ? theme.palette.charts.defaultBar : theme.palette.charts.defaultBarSelected,
        ...rankingItem,
    });

    return (
        <ResponsiveContainer width="100%" height={ LABEL_FONT_SIZE * ranks.length * 2 }>
            <BarChart
                data={ranks.map(mapRankingItem)}
                layout="vertical"
            >
                <CartesianGrid strokeDasharray="1" />
                <YAxis
                    dataKey="name"
                    type="category"
                    fontSize={LABEL_FONT_SIZE}
                    width={LABEL_PANEL_WIDTH}
                    interval={0}
                    tickFormatter={nameFormatter}
                    orientation="right"
                />
                <XAxis
                    type="number"
                    tickFormatter={rankValueFormatter}
                    fontSize={LABEL_FONT_SIZE}
                />
                <Tooltip
                    content={<CustomTooltip valueLabel={rankLabel} rankValueFormatter={rankValueFormatter} />}
                />
                <Bar dataKey={rankProperty} />
            </BarChart>
        </ResponsiveContainer>
    );
};

RegionChartRanking.propTypes = {
    regionId: PropTypes.string,
    ranks: PropTypes.arrayOf(PropTypes.object),
    rankProperty: PropTypes.string,
    rankLabel: PropTypes.string,
    rankValueFormatter: PropTypes.func,
};

export default RegionChartRanking;