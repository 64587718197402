import { styled } from '@mui/material/styles';

const styledRegionLink = (RegionLinkComponent) => (
    styled(RegionLinkComponent)({
        textDecoration: 'none',
        textTransform: 'capitalize',
        color: 'inherit',
            '&:hover': {
            textDecoration: 'underline',
        },
        fontSize: '0.875rem',
    })
);

export default styledRegionLink;
