import { Helmet } from 'react-helmet-async';


// @mui
import {
    Card,
    Container,
} from '@mui/material';

export default function InfoOurDataPage() {

    return (
        <>
            <Helmet>
                <title>Twoja Okolica .Online | O Danych</title>
            </Helmet>

            <Container>

                <Card sx={{ margin: 2, padding: 2 }}>
                    <h3>O Danych</h3>
                    <p>Serwis <b>Twoja Okolica .Online</b> wykorzystuje następujące zbiory danych publicznych:</p>
                    <p><a href="https://www.openstreetmap.org/" target="_blank" rel="noreferrer">OpenStreetMap</a>. Hierarchia regionów i miejscowości wraz z ich granicami.</p>
                    <p><a href="https://bdl.stat.gov.pl/" target="_blank" rel="noreferrer">Bank Danych Lokalnych</a>. Dane demograficzne regionów.</p>
                    <p><a href="https://www.worldpop.org/" target="_blank" rel="noreferrer">WorldPop</a>. Przybliżone dane demograficzne najmniejszych miejscowości.</p>
               </Card>
            </Container>
        </>
    );
}