import PropTypes from 'prop-types';

import { useMemo, useState, useEffect } from 'react';

import servicesContext from 'servicesContext';

import SubRegionsPanel from 'components/region/SubRegionsPanel';

import RegionTeaser from './RegionTeaser';
import RegionTiles from './RegionTiles';
import RegionCharts from './RegionCharts';

const { regionInsightsPortalService } = servicesContext;

const RegionBasicInfoPanel = ({ region, regionBasics }) => {

    const { parents, children } = regionBasics;
    const [regionDetails, setRegionDetails] = useState({});

    useEffect(() => {
        const fetchData = async () => {

            const regionDetails = await regionInsightsPortalService.getRegionDetails(region.refPath);
            setRegionDetails(regionDetails);                
        };
        fetchData();

    }, [region.refPath]);


    const {
        siblings,
        regionRanks,
        regionStats,
        regionPopTrend,
        regionChildrenCounts,
        regionInsights,
        regionNeighbours,
        regionCities,
        nearbyCities,
    } = regionDetails;

    const ranks = useMemo(() => {

        if (regionRanks) {
            const { popRanks, areaRanks, densityRanks } = regionRanks;

            const getRank = (rankedItems) => rankedItems.map((item) => item.regionId).indexOf(region.id) + 1;
    
            const popRank = getRank(popRanks);
            const areaRank = getRank(areaRanks);
            const densityRank = getRank(densityRanks);
    
            return { popRank, areaRank, densityRank };
        }

        return null;

    }, [regionRanks, region.id]);

    return (
        <>
            {parents && regionPopTrend && regionRanks && ranks && regionStats &&
                <>
                    {regionChildrenCounts && regionInsights && regionNeighbours &&
                        <RegionTeaser
                            region={region}
                            parent={parents[0]}
                            popTrend={regionPopTrend}
                            ranks={ranks}
                            stats={regionStats}
                            childrenCounts={regionChildrenCounts}
                            insights={regionInsights}
                            neighbours={regionNeighbours}
                            regionCities={regionCities}
                            nearbyCities={nearbyCities}
                        />
                    }
                    <RegionTiles
                        region={region}
                        ranks={ranks}
                        stats={regionStats}
                    />
                    <RegionCharts
                        region={region}
                        parent={parents[0]}
                        siblings={siblings}
                        popTrend={regionPopTrend}
                        parentRegionRanks={regionRanks}
                        stats={regionStats}
                    />
                    <SubRegionsPanel
                        regionChildren={children}
                    />
                </>
            }            
        </>
    );
}

RegionBasicInfoPanel.propTypes = {
    region: PropTypes.object,
    regionBasics: PropTypes.object,
};

export default RegionBasicInfoPanel;