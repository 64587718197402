import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import Map, { GeolocateControl, NavigationControl, Source, Layer, useMap } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';

const BASIC_MARGIN_PX = 8;
const MIN_MAP_HEIGHT = 80;

const MapImage = ({ images }) => {

    const { current: map } = useMap();
  
    useEffect(() => {

        // eslint-disable-next-line no-restricted-syntax
        for (const { marker, url } of images) {
            if (!map.hasImage(marker)) {
                map.loadImage(url, (error, image) => {
                    if (error) throw error;
                    if (!map.hasImage(marker)) map.addImage(marker, image, { sdf: false });
                });
            }
        }
    }, [map, images]);

    return null;
};

MapImage.propTypes = {
    images: PropTypes.array,
};

const ShapeMap = ({
    minLon, maxLon, minLat, maxLat,
    shapes,
    mapStyle = 'mapbox://styles/mapbox/streets-v11',
    accessToken, 
    images = [],
}) => {

    const [viewport, setViewport] = useState(null);

    const [dimensions, setDimensions] = useState({
        width: null, height: null,
    });

    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);

    useEffect(() => {
        const { width, height } = mapContainerRef.current.getBoundingClientRect();
        setDimensions({ width, height });
    }, []);

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            const entry = entries[0];
            const { width, height } = entry.contentRect;
            setDimensions({ width, height });
        });
        observer.observe(mapContainerRef.current);
    
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        
        if (dimensions.width && dimensions.height && dimensions.height > MIN_MAP_HEIGHT) {

            const vp = new WebMercatorViewport(dimensions);
            const {longitude, latitude, zoom} = vp.fitBounds(
                [
                    [minLon, minLat],
                    [maxLon, maxLat]
                ],
                {
                    padding: 40
                }
            );

            setViewport({ longitude, latitude, zoom });
        }

    }, [dimensions.width, dimensions.height, minLon, minLat, maxLon, maxLat]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div ref={mapContainerRef} style={{ height: '100%' }}>
            {accessToken && mapStyle ? 
                <>
                    {viewport &&
                        <Map
                            {...viewport}
                            ref={mapRef}
                            onMove={evt => setViewport(evt.viewState)}
                            mapStyle={mapStyle}
                            mapboxAccessToken={accessToken}
                        >
                            <MapImage images={images} />
                            <GeolocateControl
                                style={{ right: BASIC_MARGIN_PX, top: BASIC_MARGIN_PX * 9 }}
                                positionOptions={{enableHighAccuracy: true}}
                                trackUserLocation
                            />
                            <NavigationControl
                                style={{ right: BASIC_MARGIN_PX, top: BASIC_MARGIN_PX }}
                                showCompass={false}
                            />
                            {shapes.map(({ featureType, shapeCoordinates, layerType, layerPaint, layerLayout }, idx) =>
                                <Source
                                    key={idx}
                                    id={`shape-source-${idx}`}
                                    type="geojson"
                                    data={{ type: 'Feature', geometry: { type: featureType, coordinates: shapeCoordinates } }}
                                >
                                    <Layer
                                        id={`shape-source-layer-${idx}`}
                                        type={layerType || 'line'}
                                        paint={layerPaint || {}}
                                        layout={layerLayout || {}}
                                    />
                                </Source>
                            )}
                        </Map>
                    }
                </>
                :
                <Typography
                    color="error"
                    display="block"
                    variant="caption"
                >
                    Error occured when displaying the map
                </Typography>
            }
        </div>
    );
}

ShapeMap.propTypes = {
    minLon: PropTypes.number,
    maxLon: PropTypes.number,
    minLat: PropTypes.number,
    maxLat: PropTypes.number,
    shapes: PropTypes.arrayOf(PropTypes.shape({
        shapeCoordinates: PropTypes.array,
        featureType: PropTypes.string,
        layerType: PropTypes.string,    
        layerPaint: PropTypes.object,    
        layerLayout: PropTypes.object,    
    })),
    accessToken: PropTypes.string,
    mapStyle: PropTypes.string,
    images: PropTypes.array,
};

export default ShapeMap;