const getPopulationAsString = (population) => {
    if (population !== null) {
        return new Intl.NumberFormat('pl-PL').format(population);
    }
    
    return '???';
};

const getAreaAsString = (area) => {
    if (area !== null) {
        const areaSquareKms = Math.round(area / (1000 * 1000));
        return new Intl.NumberFormat('pl-PL').format(areaSquareKms);
    }
    
    return '???';
};

const getDensityAsString = (density) => {
    if (density !== null) {
        const densitySquareKms = Math.round(density * (1000 * 1000));
        return new Intl.NumberFormat('pl-PL').format(densitySquareKms);
    }

    return '???';
};

export { getPopulationAsString, getAreaAsString, getDensityAsString };