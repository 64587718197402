import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Breadcrumbs from './Breadcrumbs';
import SelectSubRegion from './SelectSubRegion';

const barSx = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: { md: 'center' },
    justifyContent: { xs: 'flex-start', md: 'space-between' },
};

const Header = ({ region, regionParents, regionChildren }) => (
    <Box sx={barSx}>
        <Breadcrumbs
            region={region}
            regionParents={regionParents}
        />
        <Box sx={{ pt: {xs: 1, md: 0 }}}>
            <SelectSubRegion regionChildren={regionChildren} />
        </Box>
    </Box>
);

Header.propTypes = {
    region: PropTypes.object.isRequired,
    regionParents: PropTypes.array.isRequired,
    regionChildren: PropTypes.array.isRequired,
};

export default Header;