import { useState, useMemo, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Card } from '@mui/material';
import { useTheme } from '@mui/system';

import GeoRegion from 'services/GeoRegion';
 
import servicesContext from 'servicesContext';

import Header from './Header';
import RegionPanel from './RegionPanel/RegionPanel';
import CountryPanel from './CountryPanel';

const { regionInsightsPortalService } = servicesContext;

export default function RegionPage() {

    const theme = useTheme();

    const p = { p: 2 };
    const h = { height: '100%' };

    const { voivodeshipRefName, countyRefName, communityRefName, cityRefName } = useParams();
    const regionRefPath = useMemo(
        () => GeoRegion.buildRefPath(
            voivodeshipRefName, countyRefName, communityRefName, cityRefName,
        ),
        [voivodeshipRefName, countyRefName, communityRefName, cityRefName],
    );

    const [region, setRegion] = useState(null);
    const [regionBasics, setRegionBasics] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const regionBasics = await regionInsightsPortalService.getRegionBasics(regionRefPath);
            setRegion(regionBasics.region);
            setRegionBasics(regionBasics);
        };
        fetchData();

    }, [regionRefPath]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', ...h }}>
                <Box sx={{ width: { xs: '80%', lg: '960px' }, minWidth: { xs: '380px' }, ...p, ...h  }} >
                    <Card sx={{ ...h }} >
                        {region && regionBasics &&
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', ...h }}>
                                <Box sx={{ borderBottom: `1px solid ${theme.palette.primary.lighter}`, ...p }}>
                                    <Header
                                        key={region.id}
                                        region={region}
                                        regionParents={regionBasics.parents}
                                        regionChildren={regionBasics.children}
                                    />
                                </Box>
                                <Box sx={{ ...h }}>
                                    { region.refPath !== GeoRegion.COUNTRY_REF_PATH ?
                                        <RegionPanel
                                            key={region.id}
                                            region={region}
                                            regionBasics={regionBasics}
                                        />
                                        :
                                        <CountryPanel
                                            regionChildren={regionBasics.children}
                                        />
                                    }
                                </Box>
                            </Box>
                        }
                    </Card>
                </Box>
            </Box>
        </>
    );
}
