import React from 'react';

import { AppBar, Box, Toolbar } from '@mui/material';

import { useTheme } from '@mui/system';

import AboutMenu from './AboutMenu';

const BottomAppBar = () => {

    const theme = useTheme();

    return (
        <AppBar
            position="static"
            sx={{
                borderTop: `1px solid ${theme.palette.primary.light}`,
                backgroundColor: 'white',
                boxShadow: 'none',
                display: {xs: 'flex', md: 'none' }
            }}
        >
            <Toolbar sx={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                <Box sx={{ pt: 2 }}>
                    <AboutMenu />
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default BottomAppBar;
