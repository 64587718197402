import { Navigate, useRoutes } from 'react-router-dom';

import NavigationLayout from 'layouts/navigation';
import SimpleLayout from 'layouts/simple';

import RegionPage from 'pages/RegionPage';
import InfoOurDataPage from 'pages/InfoOurDataPage';
import InfoAboutServicePage from 'pages/InfoAboutServicePage';
import InfoContactPage from 'pages/InfoContactPage';
import Page404 from 'pages/Page404';

export default function Router() {
    const routes = useRoutes([
        {
            path: '/',
            element: <NavigationLayout />,
            children: [
                { element: <RegionPage />, index: true },
            ],
        },
        {
            path: '/region',
            element: <NavigationLayout />,
            children: [
                { element: <Navigate to="/" />, index: true },
                { path: ':voivodeshipRefName', element: <RegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName', element: <RegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName/:communityRefName', element: <RegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName/:communityRefName/:cityRefName', element: <RegionPage /> },
            ],
        },
        {
            path: '/tree', // kept here only for backwards compatibility
            element: <NavigationLayout />,
            children: [
                { element: <Navigate to="/" />, index: true },
                { path: ':voivodeshipRefName', element: <RegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName', element: <RegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName/:communityRefName', element: <RegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName/:communityRefName/:cityRefName', element: <RegionPage /> },
            ],
        },
        {
            path: '/our-data-info',
            element: <NavigationLayout />,
            children: [
                { element: <InfoOurDataPage />, index: true },
            ],
        },
        {
            path: '/about-service-info',
            element: <NavigationLayout />,
            children: [
                { element: <InfoAboutServicePage />, index: true },
            ],
        },
        {
            path: '/contact-info',
            element: <NavigationLayout />,
            children: [
                { element: <InfoContactPage />, index: true },
            ],
        },
        {
            path: '/404',
            element: <SimpleLayout />,
            children: [
                { element: <Page404 />, index: true },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
