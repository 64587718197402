import PropTypes from 'prop-types';

import RegionAmenitiesPanel from './RegionAmenitiesPanel';

const LEISURE_CATEGORY_GROUPS = [
    { key: 'gastronomy', label: 'regionAmenities.amenity.gastronomy', title: 'regionAmenities.amenity.gastronomy.long' },
    { key: 'tourism', label: 'regionAmenities.amenity.tourism', title: 'regionAmenities.amenity.tourism.long' },
    { key: 'hotels', label: 'regionAmenities.amenity.hotels', title: 'regionAmenities.amenity.hotels.long' },
    { key: 'recreation', label: 'regionAmenities.amenity.recreation', title: 'regionAmenities.amenity.recreation.long' },
    { key: 'entertainment', label: 'regionAmenities.amenity.entertainment', title: 'regionAmenities.amenity.entertainment.long' },
];

const RegionLeisurePanel = ({ region }) => (
    <RegionAmenitiesPanel 
        region={region}
        categoryGroups={LEISURE_CATEGORY_GROUPS}
    />
);

RegionLeisurePanel.propTypes = {
    region: PropTypes.object,
};

export default RegionLeisurePanel;
