import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

const getAmenityTitleLine = (amenity, categoryNames, t) => {

    const segments = [];
    if (amenity.name) {
        segments.push(amenity.name);
    }
    if (amenity.brand && amenity.brand !== amenity.name) {
        segments.push(amenity.brand);
    }
    segments.push(categoryNames[amenity.category]);

    if (amenity.tags.website) {
        segments.push(
            <a
                href={amenity.tags.website}
                style={{ color: 'inherit' }}
                target='_blank'
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
            >
                {t('regionAmenities.labelAmenityWebsite')}
            </a>
        );
    }
    if (amenity.tags.wikipedia) {
        const [languageCode, articleTitle] = amenity.tags.wikipedia.split(':');
        segments.push(
            <a
                href={`https://${languageCode}.wikipedia.org/wiki/${articleTitle}`}
                style={{ color: 'inherit' }}
                target='_blank'
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
            >
                {t('regionAmenities.labelAmenityWikipedia')}
            </a>
        );
    }

    return segments;
}

const AmenityTitleLine = ({ amenity, categoryNames }) => {

    const { t } = useTranslation();

    return (
        <>
            {getAmenityTitleLine(amenity, categoryNames, t).map((segment, index, segments) =>
                <span key={index}>
                    {segment}
                    { (index < segments.length - 1) && <> - </>}
                </span>
            )}
        </>
    );
};

AmenityTitleLine.propTypes = {
    amenity: PropTypes.object,
    categoryNames: PropTypes.object,
};

const AmenityAddress = ({ amenity }) => {

    const address = amenity.address || {};
    const { street, houseNumber, postCode, city } = address;

    const segments = [];
    if (street && houseNumber) {
        segments.push(`${street} ${houseNumber}`);
    } else
    if (city && houseNumber) {
        segments.push(`${city} ${houseNumber}`);
    } else
    if (street) {
        segments.push(street);
    }
    
    if (postCode && city) {
        segments.push(`${postCode} ${city}`);
    }

    return (
        <>
            {segments.length > 0 ?
                <>
                    <>
                    {segments.map((snippet, index, segments) =>
                        <span key={index}>
                            {snippet}
                            { (index < segments.length - 1) && <>, </>}
                        </span>
                    )}
                    </>
                </>
                :
                <>{amenity.lowestRegionName}</>
            }
        </>
    );
};

AmenityAddress.propTypes = {
    amenity: PropTypes.object,
};

export { AmenityAddress, AmenityTitleLine };