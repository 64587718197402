import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import MessagePanel from 'components/MessagePanel';

import servicesContext from 'servicesContext';

import RegionMap from './RegionMap';
import { getValuesForRegionMap } from './textsValues';

const { administrativeUnitsService } = servicesContext;

const RegionMapPanel = ({ region, accessToken }) => {

    const { t } = useTranslation();
    const [boundaries, setBoundaries] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            const regionWithBoundaries = await administrativeUnitsService.getAdmistrativeUnitById(
                region.id,
            );
            setBoundaries(regionWithBoundaries.boundaries);
        };
        fetchData();

    }, [region.id]);

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {boundaries &&
                <RegionMap
                    boundaries={boundaries}
                    accessToken={accessToken}
                />
            }
            <Box>
                <MessagePanel message={t('regionMap', getValuesForRegionMap(region))} />
            </Box>
        </Box>
    );
};

RegionMapPanel.propTypes = {
    region: PropTypes.object,
    accessToken: PropTypes.string,
};

export default RegionMapPanel;
