import propTypes from 'prop-types';

import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

import MessagePanel from 'components/MessagePanel';
import StatsTile from 'components/StatsTile';

import GeoRegion from 'services/GeoRegion';

import { getPopulationAsString, getAreaAsString, getDensityAsString } from 'utils/statsFormatters';
import { getValuesForRegionStatsTiles } from './textsValues';

const rankTranslationKeys = {
    [GeoRegion.LEVEL.VOIVODESHIP]: 'regionStats.tileFooterRankVoivodeship',
    [GeoRegion.LEVEL.COUNTY]: 'regionStats.tileFooterRankCounty',
    [GeoRegion.LEVEL.COMMUNITY]: 'regionStats.tileFooterRankCommunity',
    [GeoRegion.LEVEL.CITY]: 'regionStats.tileFooterRankCity',
};

const RegionTiles = ({ region, ranks, stats }) => {

    const { t } = useTranslation();

    const rankTranslationKey = rankTranslationKeys[region.level];

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                <StatsTile
                    title={t('regionStats.tileTitlePopulation')}
                    contentNumber={getPopulationAsString(stats && stats.population)}
                    footer={ranks && t(rankTranslationKey, { rank: ranks.popRank })}
                />
                <StatsTile
                    title={t('regionStats.tileTitleArea')}
                    contentNumber={getAreaAsString(stats && stats.area)}
                    contentUnits={t('regionStats.tileAreaUnits')}
                    footer={ranks && t(rankTranslationKey, { rank: ranks.areaRank })}
                />
                <StatsTile
                    title={t('regionStats.tileTitleDensity')}
                    contentNumber={getDensityAsString(stats && stats.density)}
                    contentUnits={t('regionStats.tileDensityUnits')}
                    footer={ranks && t(rankTranslationKey, { rank: ranks.densityRank })}
                />
            </Box>
            <MessagePanel
                message={stats && t('regionStatsTiles', getValuesForRegionStatsTiles(region, stats))}
                mx={2}
                dangerous
            />
        </>
    );
};

RegionTiles.propTypes = {
    region: propTypes.object,
    ranks: propTypes.object,
    stats: propTypes.object,
};

export default RegionTiles;